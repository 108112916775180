

import { Options } from "vue-class-component";

import PageBase from "@/shared/components/common/PageBase";
import HeaderMessage from "@/components/HeaderMessage.vue";
import { ButtonClicked } from "@/shared/enums/ButtonClicked";
import { confirmOk, confirmYesNo } from "@/shared/components/common/AlertDialog.vue";
import { CalibrationEventSupport } from "@/support/CalibrationEvent";
import { CalibrationEvent } from "@/shared/enums/CalibrationEvent";
import { DataRequest } from "@/shared/support/Data";
import { CameraParserDto } from "@/shared/models/CameraParserDto";
import { CameraStatus } from "@/shared/enums/CameraStatus";
import { AUGGIEAVAILABLE } from "@/main";
import { Global } from "@/support/GlobalData";
import { SignalR } from "@/support/SignalR";
import CameraViewerConfiguration from "@/shared/models/CameraViewerConfiguration";
import { SessionType } from "@/shared/enums/SessionType";
import { DeviceStatusDto } from "@/shared/models/DeviceStatusDto";
import ChunkedUploadDto from "@/shared/models/ChunkedUploadDto";
import ProgressBar from "@/shared/components/common/ProgressBar.vue";
import Toast from "@/shared/support/Toast";

@Options({
    components: {
        HeaderMessage,
        ProgressBar,
    }
})
export default class CalibrationCameraReady extends PageBase {

    DEBUG = process.env.VUE_APP_DEBUG === "1";

    imageUrl = "";

    cameraRendering = false;
    sendingImage = false;
    awaitingConfirmation = false;
    gatherImageData = false;
    cameraTimeout = 0;
    doYawRollValid = false;
    doLightIntensity = false;
    yawRollValid: boolean = false;

    uploadProgressMinimum = 0;
    uploadProgressMaximum = 100;
    uploadProgressValue = 0;

    confirmProgressMinimum = 0;
    confirmProgressMaximum = 15; // assume 15 seconds for validation
    confirmProgressValue = 0;

    gatherProgressMinimum = 0;
    gatherProgressMaximum = 100;
    gatherProgressValue = 0;

    hasAuggie(): boolean {
        return AUGGIEAVAILABLE;
    }

    async getCameraInfo(): Promise<void> {

        this.loading = true;

        this.cameraRendering = true;

        let cameraConfig: CameraViewerConfiguration|null = null;
        if (Global.IsDemo()) {

            cameraConfig = await this.loadDemoConfiguration();
            console.debug(`CameraViewerConfiguration: ${JSON.stringify(cameraConfig)}`);

        } else {
            try {
                const dr = new DataRequest();
                dr.autoToastOnFailure = false;
                const session = Global.CalibrationSession!;
                cameraConfig = await dr.$get<CameraViewerConfiguration>("/Service/CalibrationSession/GetCameraViewerConfiguration", { CalibrationSessionGuid: session.CalibrationSessionGuid });
            } catch (error) {
                cameraConfig = null;
            }
        }

        this.loading = false;

        if (!cameraConfig) {
            this.cameraRendering = false;
            confirmOk("Unable to obtain camera information");
            this.$router.push("/Calibration/CameraSetup");
            return;
        }

        Global.MainApp.setCameraConfig(cameraConfig);
        this.loadCameraImage();
    }

    loadCameraImage(): void {
        this.loading = true;

        if (!AUGGIEAVAILABLE) {
            setTimeout((): void => {
                this.imageUrl = "/img/Toyota_Corolla_2019_originalframe_1628026473.png";
                this.loading = false;
                SignalR.onGetCameraAlignmentImage = this.getCameraAlignmentImage;
                CalibrationEventSupport.sendEvent(CalibrationEvent.CameraAlignmentReached);
                this.getCurrentStatusForListeners();
            }, 500);
        } else {
            setTimeout((): void => {
                this.setLightIntensity(Global.LightIntensity);
                Auggie.Camera.InitializeRender(JSON.stringify(Global.CameraViewerConfiguration));
                Auggie.Camera.RenderProcessedCameraImage();
                this.yawRollValid = this.doYawRollValid ? Auggie.Camera.YawRollValid() : true;
                this.loading = false;
                this.renderCameraImage();
                SignalR.onGetCameraAlignmentImage = this.getCameraAlignmentImage;
                CalibrationEventSupport.sendEvent(CalibrationEvent.CameraAlignmentReached);
                this.getCurrentStatusForListeners();
            }, 10);// allow update of form before rendering camera
        }
    }
    renderCameraImage(): void {
        this.cameraTimeout = setTimeout((): void => {
            this.cameraTimeout = 0;
            Auggie.Camera.RenderProcessedCameraImage();
            this.yawRollValid = this.doYawRollValid ? Auggie.Camera.YawRollValid() : true;
            this.renderCameraImage();
        }, 10) as unknown as number;
    }
    clearCameraTimeout(): void {
        if (this.cameraTimeout) {
            clearTimeout(this.cameraTimeout);
            this.cameraTimeout = 0;
        }
    }
    clearLights(): void {
        this.setLightIntensity(0);
    }
    terminateCameraRender(): void {
        if (AUGGIEAVAILABLE) {
            Auggie.Camera.TerminateRender();
        }
    }

    nextClicked(): void {
        SignalR.onGetCameraAlignmentImage = null;
        SignalR.onCameraVerificationByTechnician = null;
        SignalR.onCameraVerificationByCompanion = null;
        this.clearCameraTimeout();
        this.loading = true;

        // get a processed camera image in demo mode so we can display it later
        if (Global.IsDemo()) {
            if (!AUGGIEAVAILABLE)
                Global.DemoCameraImage = "/img/Toyota_Corolla_2019_originalframe_1628026473.png"; // debugging stand-alone only
            else {
                Global.DemoCameraImage = Auggie.Camera.GetProcessedCameraImage();
            }
        }

        if (AUGGIEAVAILABLE) {
            this.gatherCameraData();
        } else {
            this.fetchCameraImage();
        }
    }
    nextClickedCompanion(): void {
        if (this.yawRollValid) {
            this.nextClicked();
        } else {
            this.getCurrentStatusForListeners(false);
        }
    }

    gatherCameraData(): void {
        if (!AUGGIEAVAILABLE || !Global.IsVersionAtLeast("2023.205.1")) {
            this.fetchCameraImage();
            return;
        }

        Auggie.Camera.EndCameraDisplay();// shut down the overlay

        this.gatherImageData = true;
        this.cameraRendering = false;

        this.$forceUpdate();

        console.log("Gathering");

        // show the progress bar
        this.gatherProgressMinimum = 0;
        this.gatherProgressMaximum = Auggie.Camera.GetMissingFrames();
        console.log(`Missing frames ${this.gatherProgressMaximum}`);
        this.gatherProgressValue = 0;

        this.gatherOneCameraImage();
    }

    gatherOneCameraImage(): void {
        console.log(`Missing frames ${this.gatherProgressMaximum}`);
        if (!Auggie.Camera.MaxFramesReached()) {
            setTimeout((): void => { // make sure other things are also dispatched while we're getting camera images
                Auggie.Camera.RenderProcessedCameraImage();
                console.log(`Got Frame, Missing frames ${Auggie.Camera.GetMissingFrames()}`);
                this.gatherProgressValue++;
                this.gatherOneCameraImage();// again
            }, 10);
            return;
        }
        this.fetchCameraImage();
    }

    fetchCameraImage(): void {
        // get an unprocessed camera image and send it to the camera parser.
        let cameraImage: string;
        let cameraData: string|null;
        if (!AUGGIEAVAILABLE) {
            cameraImage = "/img/Toyota_Corolla_2019_originalframe_1628026473.png"; // debugging stand-alone only
            const fakeData = {
                "Roll": 0.51154808003420316,
                "Yaw": 0.67462799417747665,
                "YawRollDecimals": 2,
                "MarkerCorners": [
                    [
                        [
                            {
                                "X": 1206,
                                "Y": 684
                            },
                            {
                                "X": 1311,
                                "Y": 682
                            },
                            {
                                "X": 1330,
                                "Y": 770
                            },
                            {
                                "X": 1219,
                                "Y": 771
                            }
                        ],
                        [
                            {
                                "X": 778,
                                "Y": 562
                            },
                            {
                                "X": 875,
                                "Y": 559
                            },
                            {
                                "X": 868,
                                "Y": 630
                            },
                            {
                                "X": 766,
                                "Y": 635
                            }
                        ],
                        [
                            {
                                "X": 1186,
                                "Y": 548
                            },
                            {
                                "X": 1280,
                                "Y": 547
                            },
                            {
                                "X": 1296,
                                "Y": 617
                            },
                            {
                                "X": 1196,
                                "Y": 619
                            }
                        ],
                        [
                            {
                                "X": 1356,
                                "Y": 456
                            },
                            {
                                "X": 1443,
                                "Y": 457
                            },
                            {
                                "X": 1465,
                                "Y": 514
                            },
                            {
                                "X": 1373,
                                "Y": 513
                            }
                        ],
                        [
                            {
                                "X": 1090,
                                "Y": 456
                            },
                            {
                                "X": 1178,
                                "Y": 455
                            },
                            {
                                "X": 1185,
                                "Y": 513
                            },
                            {
                                "X": 1093,
                                "Y": 513
                            }
                        ],
                        [
                            {
                                "X": 756,
                                "Y": 700
                            },
                            {
                                "X": 862,
                                "Y": 696
                            },
                            {
                                "X": 854,
                                "Y": 786
                            },
                            {
                                "X": 740,
                                "Y": 791
                            }
                        ],
                        [
                            {
                                "X": 823,
                                "Y": 462
                            },
                            {
                                "X": 911,
                                "Y": 459
                            },
                            {
                                "X": 904,
                                "Y": 516
                            },
                            {
                                "X": 810,
                                "Y": 518
                            }
                        ]
                    ]
                ],
                "MarkerIds": [
                    [
                        13,
                        11,
                        12,
                        2,
                        1,
                        10,
                        0
                    ]
                ]
            };
            cameraData = JSON.stringify(fakeData);
        } else {
            if (Global.IsVersionAtLeast("2022.255.0")) {
                cameraImage = Auggie.Camera.GetLastFrame();
                cameraData = Auggie.Camera.GetLastData();
            } else {
                cameraImage = Auggie.Camera.GetCameraImage();
                cameraData = null;
            }
        }

        this.clearLights();
        this.terminateCameraRender();
        this.cameraRendering = false;

        fetch(cameraImage)
            .then((response): Promise<Blob> => {
                return response.blob();
            })
            .then((blob: Blob): void => {
                this.loading = false;
                this.sendCameraImageToValidate(blob, cameraData);
            })
            .catch((reason): void => {
                this.loading = false;
                const msg = "An error occurred retrieving the camera image";
                CalibrationEventSupport.sendEventDeviceFailure(msg);
                confirmOk(msg);
                this.$router.push("/Calibration/CameraSetup");
            });
    }

    sendCameraImageToValidate(blob: Blob|null, cameraData: string|null): void {

        if (!Global.CalibrationSession) {
            console.error("Sending camera image without active session");
            confirmOk("The current session is no longer available due to network errors");
            this.$router.push("/");
            return;
        }

        this.sendingImage = true;
        this.getCurrentStatusForListeners();

        if (Global.IsDemo()) {

            let seconds = 1;
            const maxSeconds = Number(process.env.VUE_APP_DEMODELAY!);
            const interval = setInterval((): void => {
                const uploadProgress = this.$refs.refuploadprogress;
                if (uploadProgress) {
                    this.uploadProgressMinimum = 0;
                    this.uploadProgressMaximum = maxSeconds;
                    this.uploadProgressValue = seconds;
                }
                ++seconds;
                if (seconds > maxSeconds) {
                    clearInterval(interval);
                    if (this.pageGone) return;
                    this.saveUploadedValidationImage({} as ChunkedUploadDto, cameraData);
                }
            }, 1000);

        } else {

            const dr = new DataRequest();
            dr.$postChunkedFile(Global.CalibrationSession!.CalibrationSessionGuid, blob!, (count:number, total:number):void => {
                const uploadProgress = this.$refs.refuploadprogress;
                if (uploadProgress) {
                    this.uploadProgressMinimum = 0;
                    this.uploadProgressMaximum = total;
                    this.uploadProgressValue = count;
                }
            })
                .then((uploadDto: ChunkedUploadDto): void => {
                    if (this.pageGone) return;
                    this.saveUploadedValidationImage(uploadDto, cameraData);
                })
                .catch((reason): void => {
                    if (this.pageGone) return;
                    this.sendingImage = false;
                    const msg = `An unexpected error occurred verifying the camera position - ${reason}`;
                    CalibrationEventSupport.sendEventDeviceFailure(msg);
                    confirmOk(msg);
                    this.$router.push("/Calibration/CameraSetup");
                });
        }
    }

    saveUploadedValidationImage(uploadDto: ChunkedUploadDto, cameraData: string|null): void {

        this.awaitingConfirmation = true;

        if (Global.IsDemo()) {
            this.confirmProgressMaximum = Number(process.env.VUE_APP_DEMODELAY!);
            this.confirmProgressMinimum = 0;
            this.confirmProgressValue = 0;

            let seconds = 0;
            const interval = setInterval((): void => {
                this.confirmProgressValue = seconds;
                if (seconds < this.confirmProgressMaximum) {
                    ++seconds;
                } else {
                    clearInterval(interval);
                    if (this.pageGone) return;
                    this.confirmationReceived(true);
                }
            }, 1000);

        } else {
            let seconds = 0;
            const interval = setInterval((): void => {
                this.confirmProgressValue = seconds;
                if (seconds < this.confirmProgressMaximum - 1)
                    ++seconds;
                else
                    clearInterval(interval);
            }, 1000);

            const dr = new DataRequest(undefined, undefined, 3);// 3 retries
            dr.$post<any, CameraParserDto>("/Service/CameraParser/Validate", {
                DeviceGuid: Global.DeviceGuid,
                CalibrationSessionGuid: Global.CalibrationSession!.CalibrationSessionGuid,
                IgnoreCameraCalibration: Global.IgnoreCameraCalibration(),
                FileName: uploadDto.FileName,
                FileSize: uploadDto.FileSize,
                ContentType: uploadDto.ContentType,
            }, cameraData)
                .then((parserResult: CameraParserDto): void => {
                    clearInterval(interval);
                    if (this.pageGone) return;
                    this.confirmProgressValue = this.confirmProgressMaximum;
                    this.sendingImage = false;
                    if (parserResult.UpdateResult.Success) {
                        if (parserResult.CameraStatus === CameraStatus.Found) {
                            this.confirmationReceived(true);
                        } else {
                            this.confirmationReceived(false);
                        }
                    } else {
                        this.confirmationReceived(false);
                    }
                })
                .catch((reason): void => {
                    clearInterval(interval);
                    if (this.pageGone) return;
                    this.sendingImage = false;
                    const msg = `An unexpected error occurred verifying the camera position - ${reason}`;
                    CalibrationEventSupport.sendEventDeviceFailure(msg);
                    confirmOk(msg);
                    this.$router.push("/Calibration/CameraSetup");
                });
        }
    }

    sendCameraAlignmentImage(blob: Blob|null): void {

        if (!Global.CalibrationSession) {
            console.error("Sending camera image without active session");
            confirmOk("The current session is no longer available due to network errors");
            this.$router.push("/");
            return;
        }

        const dr = new DataRequest();
        dr.$postChunkedFile(Global.CalibrationSession!.CalibrationSessionGuid, blob!, (count:number, total:number):void => { /* deliberately empty */ })
            .then((uploadDto: ChunkedUploadDto): void => {
                const dr = new DataRequest();
                dr.$post<any, CameraParserDto>("/Service/CameraParser/CameraAlignmentImage", {
                    DeviceGuid: Global.DeviceGuid,
                    CalibrationSessionGuid: Global.CalibrationSession!.CalibrationSessionGuid,
                    FileName: uploadDto.FileName,
                    FileSize: uploadDto.FileSize,
                    ContentType: uploadDto.ContentType,
                });
            });
    }

    getCameraAlignmentImage(): void {

        // get a processed camera image and upload it
        let cameraImage: string;
        if (!AUGGIEAVAILABLE)
            cameraImage = "/img/Toyota_Corolla_2019_originalframe_1628026473.png"; // debugging stand-alone only
        else {
            cameraImage = Auggie.Camera.GetProcessedCameraImage();
        }

        fetch(cameraImage)
            .then((response): Promise<Blob> => {
                return response.blob();
            })
            .then((blob: Blob): void => {
                this.loading = false;
                this.sendCameraAlignmentImage(blob);
            })
            .catch((reason): void => {
                this.loading = false;
            });
    }

    confirmationReceived(accept: boolean): void {
        this.awaitingConfirmation = false;
        if (Global.TechnicianName) {
            // under technician control, always go to in progress page. technician will decide what to do
            // based on the camera image which has pass/fail information.
            this.$router.push("/Calibration/InProgress");
        } else {
            if (accept) {
                if (Global.SessionType === SessionType.SelfCalibration)
                    this.$router.push("/Calibration/CameraImage");
                else
                    this.$router.push("/Calibration/Contacting");
            } else {
                confirmOk("The Auggie is not correctly positioned. Please verify Auggie position.");
                this.$router.push("/Calibration/CameraSetup");
            }
        }
    }

    setLightIntensity(intensity: number): void {
        if (AUGGIEAVAILABLE) {
            if (this.doLightIntensity)
                Auggie.Lights.SetIntensity(intensity);
            else {
                if (intensity < 1)
                    Auggie.Camera.LightOff();
                else
                    Auggie.Camera.LightOn();
            }
        }
    }

    cancelClicked(): void {

        const wasCameraRendering = this.cameraRendering;

        if (wasCameraRendering) {
            SignalR.onGetCameraAlignmentImage = null;
            this.clearCameraTimeout();
            this.clearLights();
            this.terminateCameraRender();
            this.cameraRendering = false;
        }
        confirmYesNo("Are you sure you want to cancel this calibration?")
            .then((clicked: ButtonClicked): void => {
                if (clicked === ButtonClicked.Ok) {
                    CalibrationEventSupport.sendEvent(CalibrationEvent.CalibrationCustomerCanceled);
                    Global.MainApp.clearSession();
                    this.$router.push("/");
                } else {
                    if (wasCameraRendering) {
                        this.cameraRendering = true;
                        if (AUGGIEAVAILABLE) {
                            this.setLightIntensity(Global.LightIntensity);
                            Auggie.Camera.InitializeRender(JSON.stringify(Global.CameraViewerConfiguration));
                            Auggie.Camera.RenderProcessedCameraImage();
                            this.yawRollValid = this.doYawRollValid ? Auggie.Camera.YawRollValid() : true;
                            this.renderCameraImage();
                            SignalR.onGetCameraAlignmentImage = this.getCameraAlignmentImage;
                        }
                    }
                }
            });
    }

    getCurrentStatusForListeners(yawRollValid?: boolean): void {
        if (this.sendingImage) {
            CalibrationEventSupport.sendEventStatus({
                Page: "CalibrationCameraReady",
                CameraVerifying: true,
            } as DeviceStatusDto);
        } else {
            CalibrationEventSupport.sendEventStatus({
                Page: "CalibrationCameraReady",
                CameraVerifying: false,
                YawRollValid: yawRollValid,
            } as DeviceStatusDto);
        }
    }

    async loadDemoConfiguration(): Promise<CameraViewerConfiguration|null> {
        const distortion = await this.loadDemoCameraDistortion();
        if (!distortion) return null;
        const matrix = await this.loadDemoCameraMatrix();
        if (!matrix) return null;
        const offsets = await this.loadDemoCameraOffsets();
        if (!offsets) return null;
        const cameraParms = await this.loadDemoCameraParameters();
        if (!cameraParms) return null;

        const config : CameraViewerConfiguration = {
            CarYear: Global.Year.toString(),
            CarMake: Global.Make,
            CarModel: Global.Model,
            CarCameraHeight: Global.DemoVehicle!.CameraHeight,
            CarTargetHeight:1300.0,// Not Used for Viewer in Demo mode
            CarTargetToCamera:3700.0,// Not Used for Viewer in Demo mode
            CarCameraMountingOffset: Global.DemoVehicle!.CameraMountingOffset,
            CarWindshieldAngle: Global.DemoVehicle!.WindshieldAngle,
            YawRollDecimals: 1,
            CameraDistortion: distortion,
            CameraMatrix: matrix,
            AuggieCameraPitch: offsets[0],
            AuggieCameraRoll: offsets[1],
            AuggieCameraYaw: offsets[2],
            CameraToScreenXDif: cameraParms[0],
            CameraToScreenYDif: cameraParms[1],
            CameraToScreenZDif: cameraParms[2],
            MarkerLength: cameraParms[3],
            ZOffset: cameraParms[4],
            RefractionOffset: cameraParms[5],
            ConfidenceThreshold: 0.5 // not configurable
        };
        return config;
    }
    async loadDemoCameraDistortion(): Promise<number[]|null> {
        const fileName = "CameraDistortionParameters.csv";
        const lines: string[] = await this.readLines(fileName);
        if (lines.length < 2) {
            Toast.error(`${fileName} is invalid, expected 2 lines, got ${lines.length}`);
            return null;
        }
        const distortion  = lines[1].split(/,/);
        if (distortion.length !== 5) {
            Toast.error(`${fileName} line 2 is invalid - ${lines[1]}`);
            return null;
        }
        const distArray: number[] = [];
        for (const dist of distortion) {
            const d = Number(dist);
            if (isNaN(d)) {
                Toast.error(`Invalid number in ${fileName} line 2 - ${lines[1]}`);
                return null;
            }
            distArray.push(d);
        }
        return distArray;
    }
    async loadDemoCameraMatrix(): Promise<number[]|null> {
        const fileName = "CameraIntrinsicParameters.csv";
        const lines: string[] = await this.readLines(fileName);
        if (lines.length < 4) {
            Toast.error(`${fileName} is invalid, expected 4 lines, got ${lines.length}`);
            return null;
        }
        const matrixArray: number[] = [];
        for (let l = 1 ; l < 4 ; ++l) {
            const line = lines[l];
            const matrix  = line.split(/,/);
            if (matrix.length !== 3) {
                Toast.error(`${fileName} line ${l} is invalid - ${lines[l]}`);
                return null;
            }
            for (const mat of matrix) {
                const m = Number(mat);
                if (isNaN(m)) {
                    Toast.error(`Invalid number in ${fileName} line ${l} - ${lines[l]}`);
                    return null;
                }
                matrixArray.push(m);
            }
        }
        return matrixArray;
    }
    async loadDemoCameraOffsets(): Promise<number[]|null> {
        const fileName = "CameraOffsets.csv";
        const lines: string[] = await this.readLines(fileName);
        if (lines.length < 2) {
            Toast.error(`${fileName} is invalid, expected 2 lines, got ${lines.length}`);
            return null;
        }
        const offsets  = lines[1].split(/,/);
        if (offsets.length !== 3) {
            Toast.error(`${fileName} line 2 is invalid - ${lines[1]}`);
            return null;
        }
        const offsArray: number[] = [];
        for (const offs of offsets) {
            const o = Number(offs);
            if (isNaN(o)) {
                Toast.error(`Invalid number in ${fileName} line 2 - ${lines[1]}`);
                return null;
            }
            offsArray.push(o);
        }
        return offsArray;
    }
    async loadDemoCameraParameters(): Promise<number[]|null> {
        const fileName = "CameraParameters.csv";
        const lines: string[] = await this.readLines(fileName);
        if (lines.length < 2) {
            Toast.error(`${fileName} is invalid, expected 2 lines, got ${lines.length}`);
            return null;
        }
        const parmeters  = lines[1].split(/,/);
        if (parmeters.length !== 6) {
            Toast.error(`${fileName} line 2 is invalid - ${lines[1]}`);
            return null;
        }
        const parmArray: number[] = [];
        for (const parm of parmeters) {
            const p = Number(parm);
            if (isNaN(p)) {
                Toast.error(`Invalid number in ${fileName} line 2 - ${lines[1]}`);
                return null;
            }
            parmArray.push(p);
        }
        return parmArray;
    }

    async readLines(file: string): Promise<string[]> {
        const response = await fetch(`/demo/calibration/${file}`);
        const blob = await response.blob();
        const contents = await blob.text();
        const lines = contents.split(/\r?\n/);
        return lines;
    }

    mounted(): void {
        SignalR.onGetCurrentStatusForListeners = this.getCurrentStatusForListeners;
        SignalR.onCameraVerificationByTechnician = this.nextClicked;
        SignalR.onCameraVerificationByCompanion = this.nextClickedCompanion;
        SignalR.onSetLightIntensity = this.setLightIntensity;

        this.doYawRollValid = false;
        this.doLightIntensity = false;
        if (AUGGIEAVAILABLE) {
            if (Global.IsVersionAtLeast("0.9.15")) {
                this.doYawRollValid = true;
                this.doLightIntensity = true;
            }
        }
        if (!this.doYawRollValid)
            this.yawRollValid = true;
        this.getCameraInfo();
    }
    unmounted(): void {
        SignalR.onGetCurrentStatusForListeners = null;
        SignalR.onGetCameraAlignmentImage = null;
        SignalR.onCameraVerificationByTechnician = null;
        SignalR.onCameraVerificationByCompanion = null;
        SignalR.onSetLightIntensity = null;

        this.clearCameraTimeout();
        this.clearLights();
        this.terminateCameraRender();
    }
    declare $refs: {
        refuploadprogress: ProgressBar,
        refconfirmprogress: ProgressBar,
        refgatherprogress: ProgressBar,
    }
}
