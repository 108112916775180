

import { AUGGIEAVAILABLE } from "@/main";
import { ButtonClicked } from "@/shared/enums/ButtonClicked";
import { confirmOk, confirmYesNo } from "@/shared/components/common/AlertDialog.vue";
import Toast from "@/shared/support/Toast";
import { Vue } from "vue-class-component";

export default class PowerOff extends Vue {

    onOffClicked(): void {
        confirmYesNo("Are you sure you want to shut down this device?")
            .then((clicked: ButtonClicked): void => {
                if (clicked === ButtonClicked.Ok) {
                    confirmOk("Please make sure to power off the device once it has shut down.")
                        .then((clicked: ButtonClicked): void => {
                            Toast.success("Shutting down ....");
                            if (AUGGIEAVAILABLE) {
                                setTimeout((): void => {
                                    Auggie.System.ShutDown();
                                }, 2000);// slight delay so the toast is shown
                            }
                        });
                }
            });
    }
}

