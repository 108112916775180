

import { Options } from "vue-class-component";

import PageBase from "@/shared/components/common/PageBase";
import HeaderMessage from "@/components/HeaderMessage.vue";
import { DataRequest } from "@/shared/support/Data";
import SelectionSupport, { SelectionEntry } from "@/shared/support/Selection";
import { VehicleFilterType } from "@/shared/enums/VehicleFilterType";
import { ButtonClicked } from "@/shared/enums/ButtonClicked";
import { confirmOk, confirmYesNo } from "@/shared/components/common/AlertDialog.vue";
import { GenericActiveStatus } from "@/shared/enums/GenericActiveStatus";
import { VehicleDto } from "@/shared/models/VehicleDto";
import { CalibrationSessionDto } from "@/shared/models/CalibrationSessionDto";
import { CalibrationType, CalibrationTypeDescription, CalibrationTypeSpecific } from "@/shared/enums/CalibrationType";
import { VehicleCalibrationDto } from "@/shared/models/VehicleCalibrationDto";
import { Global } from "@/support/GlobalData";
import { SignalR } from "@/support/SignalR";
import { CalibrationEventSupport } from "@/support/CalibrationEvent";
import { CalibrationEvent } from "@/shared/enums/CalibrationEvent";
import { SessionType } from "@/shared/enums/SessionType";
import { DeviceStatusDto } from "@/shared/models/DeviceStatusDto";
import { RegionType } from "@/shared/enums/RegionType";

@Options({
    components: {
        HeaderMessage,
    }
})
export default class CalibrationVehicle extends PageBase {

    selectedYear: number|string = "";
    yearSelections: any = null;
    selectedMake: string = "";
    makeSelections: any = null;
    selectedModel: string = "";
    modelSelections: any = null;
    selectedCalibration: CalibrationType|string = "";
    calibrationSelections: SelectionEntry[] = SelectionSupport.EnumSelections(CalibrationTypeSpecific, CalibrationTypeDescription);
    multipleCalibrations = false;
    vehicle: VehicleDto|null = null;

    makeChange():void {
        this.updateDropDownModel();
    }
    modelChange():void {
        this.updateDropDownYear();
    }
    yearChange():void {
        this.updateCalibration();
    }
    calibrationChange():void {
        // nothing to do
    }

    isCompanionControlled = false;
    isTechControlled(): boolean {
        return Global.IsTechControlled;
    }
    isControlled(): boolean {
        return this.isCompanionControlled || Global.IsTechControlled;
    }

    get selfOrAssisted(): string {
        if (Global.SessionType === SessionType.AssistedCalibration) return "Assisted Calibration";
        if (Global.SessionType === SessionType.SelfCalibration) return "Self Calibration";
        if (Global.SessionType === SessionType.AssistedTargetDisplay) return "Target Request";
        return "";
    }

    async updateDropDownMake(make?: string, model?: string, year?: number, calibration?: CalibrationType): Promise<void> {

        this.loading = true;

        let options: SelectionEntry[] = [];
        if (Global.IsDemo()) {
            const demoVehicles = await this.loadDemoVehicles();
            options = demoVehicles.map((v: VehicleDto, i: number): SelectionEntry => {
                return { Key: v.VehicleMake, Value: v.VehicleMake };
            });
            options = SelectionSupport.UniqueOnly(options, true);
            options = SelectionSupport.Sort(options);
        } else {
            try {
                const dr = new DataRequest();
                options = await dr.$get<SelectionEntry[]>("/Service/Vehicle/DisplayList", {
                    "VehicleFilterType": VehicleFilterType.VehicleMake,
                    CalibrationSessionGuid: Global.CalibrationSession?.CalibrationSessionGuid,
                });
            } catch (error) {
                // already handled
            }
        }

        this.loading = false;

        this.makeSelections = options;
        this.selectedMake = make || "";
        this.modelSelections = [];
        this.selectedModel = "";
        this.yearSelections = [];
        this.selectedYear = "";
        this.selectedCalibration = "";
        this.multipleCalibrations = false;
        this.vehicle = null;
        this.loading = false;
        if (make && model)
            this.updateDropDownModel(model, year, calibration);
    }
    async updateDropDownModel(model?: string, year?: number, calibration?: CalibrationType): Promise<void> {

        if (!this.selectedMake) {
            this.selectedModel = "";
            this.modelSelections = [];
            this.selectedYear = "";
            this.yearSelections = [];
            this.selectedCalibration = "";
            this.multipleCalibrations = false;
            return;
        }

        this.loading = true;

        let options: SelectionEntry[] = [];
        if (Global.IsDemo()) {
            const demoVehicles = await this.loadDemoVehicles();
            options = demoVehicles.filter((v: VehicleDto, i: number, array: VehicleDto[]): boolean => {
                return v.VehicleMake === this.selectedMake;
            }).map((v: VehicleDto, i: number): SelectionEntry => {
                return { Key: v.VehicleModel, Value: v.VehicleModel };
            });
            options = SelectionSupport.UniqueOnly(options, true);
            options = SelectionSupport.Sort(options);
        } else {
            try {
                const dr = new DataRequest();
                options = await dr.$get<SelectionEntry[]>("/Service/Vehicle/DisplayList", {
                    "VehicleFilterType": VehicleFilterType.VehicleModel,
                    VehicleMake: this.selectedMake,
                    CalibrationSessionGuid: Global.CalibrationSession?.CalibrationSessionGuid,
                });
            } catch (error) {
                // already handled
            }
        }

        this.loading = false;

        this.modelSelections = options;
        this.selectedModel = model || "";
        this.yearSelections = [];
        this.selectedYear = "";
        this.selectedCalibration = "";
        this.multipleCalibrations = false;
        this.vehicle = null;
        this.loading = false;
        if (model && year)
            this.updateDropDownYear(year, calibration);
    }

    async updateDropDownYear(year?: number, calibration?: CalibrationType): Promise<void> {

        if (!this.selectedModel) {
            this.selectedYear = "";
            this.yearSelections = [];
            this.selectedCalibration = "";
            this.multipleCalibrations = false;
            return;
        }

        this.loading = true;

        let options: SelectionEntry[] = [];
        if (Global.IsDemo()) {
            const demoVehicles = await this.loadDemoVehicles();
            options = demoVehicles.filter((v: VehicleDto, i: number, array: VehicleDto[]): boolean => {
                return v.VehicleModel === this.selectedModel;
            }).map((v: VehicleDto, i: number): SelectionEntry => {
                return { Key: v.VehicleYear, Value: v.VehicleYear.toString() };
            });
            options = SelectionSupport.UniqueOnly(options, true);
            options = SelectionSupport.Sort(options);
        } else {
            try {
                const dr = new DataRequest();
                options = await dr.$get<SelectionEntry[]>("/Service/Vehicle/DisplayList", {
                    "VehicleFilterType": VehicleFilterType.VehicleYear,
                    VehicleMake: this.selectedMake,
                    VehicleModel: this.selectedModel,
                    CalibrationSessionGuid: Global.CalibrationSession?.CalibrationSessionGuid,
                });
            } catch (error) {
                // already handled
            }
        }

        this.loading = false;

        this.yearSelections = options;
        this.selectedYear = year || "";
        this.selectedCalibration = "";
        this.multipleCalibrations = false;
        this.vehicle = null;
        this.loading = false;
        if (calibration)
            this.updateCalibration(calibration);
    }
    updateCalibration(calibration?: CalibrationType): void {
        this.selectedCalibration = calibration || "";
        this.multipleCalibrations = false;
        if (!this.selectedYear)
            return;

        this.getCalibrationTypes();
    }

    reloadDropdowns(): void {
        if (Global.Make) {
            this.updateDropDownMake(Global.Make, Global.Model, Global.Year, Global.Calibration);
        } else {
            this.updateDropDownMake();
        }
    }

    async getCalibrationTypes(): Promise<void> {

        this.loading = true;

        let availableVehicles: VehicleDto[] = [];
        if (Global.IsDemo()) {

            const demoVehicles = await this.loadDemoVehicles();
            availableVehicles = demoVehicles.filter((v: VehicleDto, i: number, array: VehicleDto[]): boolean => {
                return v.VehicleMake === this.selectedMake && v.VehicleModel === this.selectedModel && v.VehicleYear === Number(this.selectedYear);
            });
            for (const a of availableVehicles) {
                a.VehicleCalibrations = [
                    {
                        VehicleCalibrationId: 1,
                        VehicleId: a.VehicleId,
                        CalibrationType: CalibrationType.OneTime,
                        ActiveInd: true,
                    },
                    {
                        VehicleCalibrationId: 1,
                        VehicleId: a.VehicleId,
                        CalibrationType: CalibrationType.Sequential,
                        ActiveInd: true,
                    },
                ] as VehicleCalibrationDto[];
            }
        } else {
            try {
                const dr = new DataRequest();
                dr.autoToastOnFailure = false;
                availableVehicles = await dr.$get<VehicleDto[]>("/Service/Vehicle/All", {
                    VehicleYear: this.selectedYear,
                    VehicleMake: this.selectedMake,
                    VehicleModel: this.selectedModel,
                    CalibrationSessionGuid: Global.CalibrationSession?.CalibrationSessionGuid,
                    StatusFilter: GenericActiveStatus.Active,
                });
            } catch (error) {
                confirmOk(`Unable to retrieve vehicle information for ${this.selectedYear} ${this.selectedMake} ${this.selectedModel}.`);
            }
        }

        this.loading = false;

        if (availableVehicles.length === 0) {
            confirmOk(`No vehicle/target image information is available for ${this.selectedYear} ${this.selectedMake} ${this.selectedModel}.`);
            return;
        }
        const vehicle = availableVehicles[0];
        const cals = vehicle.VehicleCalibrations.filter((v: VehicleCalibrationDto, index: number): boolean => {
            return v.ActiveInd;
        });
        if (cals.length <= 0) {
            confirmOk(`No vehicle calibration types are available for ${this.selectedYear} ${this.selectedMake} ${this.selectedModel}.`);
            return;
        } else if (cals.length === 1) {
            this.multipleCalibrations = false;
            this.vehicle = vehicle;
            this.selectedCalibration = cals[0].CalibrationType;
        } else { // cals.length > 1
            this.multipleCalibrations = true;
            this.vehicle = vehicle;
        }
        if (Global.IsDemo()) {
            // The current vehicle table (/demo/vehicles.json, copied from PROD DB) doesn't have TargetBrightness values.
            // Set it explicitly here. Once an updated version is available this code can be removed. Or not. It's optional.
            if (!this.vehicle.TargetBrightness)
                this.vehicle.TargetBrightness = 100;
            // The current vehicle table (/demo/vehicles.json, copied from PROD DB) doesn't have WheelArchHeightMin/Max values.
            // Set it explicitly here. Once an updated version is available this code can be removed. Or not. It's optional.
            if (!this.vehicle.WheelArchHeightMin && !this.vehicle.WheelArchHeightMax) {
                this.vehicle.WheelArchHeightMin = 500;
                this.vehicle.WheelArchHeightMin = 1500;
            }
        }

        if (this.isTechControlled()) {
            setTimeout((): void => {
                this.nextClicked();
            }, 3000);
        } else if (this.isCompanionControlled) {
            this.nextClicked();
        }
    }

    async loadDemoVehicles(): Promise<VehicleDto[]> {
        if (!CalibrationVehicle.demoVehicles) {
            try {
                this.loading = true;
                const response = await fetch("/demo/vehicles.json");
                const blob = await response.blob();
                const json = await blob.text();
                CalibrationVehicle.demoVehicles = JSON.parse(json)!;
                CalibrationVehicle.demoVehicles = CalibrationVehicle.demoVehicles!.filter((v: VehicleDto, i: number, array: VehicleDto[]): boolean =>{
                    return v.Region === RegionType.Domestic && !v.VehicleMake.includes("(UK)");// Exclude UK vehicles that have incorrect region
                });
            } catch (error) {
                confirmOk("Unable to load demo vehicles from /demo/vehicles.json");
                Global.MainApp.clearSession();
                this.$router.push("/");
            } finally {
                this.loading = false;
            }
        }
        return CalibrationVehicle.demoVehicles || [];
    }
    static demoVehicles: VehicleDto[]|null;

    nextClicked(): void {
        Global.Make = this.selectedMake;
        Global.Model = this.selectedModel;
        Global.Year = Number(this.selectedYear);
        Global.LegLengthA = this.vehicle!.LegLengthA;
        Global.LegLengthB = this.vehicle!.LegLengthB;
        Global.LegLengthC = this.vehicle!.LegLengthC;
        Global.Marker = this.vehicle!.Marker;
        Global.VehicleId = this.vehicle!.VehicleId;
        Global.DemoVehicle = this.vehicle;// Used for demo only
        Global.WheelArchHeightMin = this.vehicle!.WheelArchHeightMin;
        Global.WheelArchHeightMax = this.vehicle!.WheelArchHeightMax;
        this.startSession(this.vehicle!.VehicleId, this.selectedCalibration as CalibrationType);
    }

    async startSession(vehicleId: number, calibrationType: CalibrationType): Promise<void> {

        this.loading = true;

        let session: CalibrationSessionDto|null = null;
        if (Global.IsDemo()) {

            session = Global.MainApp.getFakeDemoSession();
            session!.CalibrationType = Global.Calibration = calibrationType;
            session!.UpdateResult = { Success: true, Message: null };

        } else {
            try {
                const dr = new DataRequest();
                dr.autoToastOnFailure = false;
                const calibrationSession = { CalibrationSessionGuid: Global.CalibrationSession!.CalibrationSessionGuid,
                    VehicleId: vehicleId,
                    DeviceGuid: Global.DeviceGuid,
                    CalibrationType: calibrationType,
                } as CalibrationSessionDto;
                session = await dr.$post<CalibrationSessionDto, CalibrationSessionDto>("/Service/CalibrationSession", null, calibrationSession);
                session.CalibrationType = Global.Calibration = calibrationType;
            } catch (reason) {
                confirmOk(`Unable to establish a session (${reason})`);
            }
        }

        this.loading = false;

        if (!session || !session.UpdateResult.Success) {
            confirmOk(session?.UpdateResult.Message || "The server was unable to establish a session");
            return;
        }
        Global.MainApp.setSession(session);
        if (this.isTechControlled()) {
            this.$router.push("/Calibration/CameraSetup");
        } else if (Global.SessionType === SessionType.AssistedCalibration || Global.SessionType === SessionType.AssistedTargetDisplay) {
            this.$router.push("/Calibration/RideHeight");
        } else {
            this.$router.push("/Calibration/Vin");
        }
    }

    setVehicleSelectionByTech(year: number, make: string, model: string, type: CalibrationType): void {
        Global.IsTechControlled = true;
        this.setVehicleSelection(year, make, model, type);
    }
    setVehicleSelectionByCompanion(year: number, make: string, model: string, type: CalibrationType): void {
        this.isCompanionControlled = true;
        this.setVehicleSelection(year, make, model, type);
    }
    setVehicleSelection(year: number, make: string, model: string, type: CalibrationType): void {
        Global.Make = make;
        Global.Model = model;
        Global.Year = year;
        Global.Calibration = type;
        setTimeout((): void => {// allow page update in case it takes longer
            this.reloadDropdowns();
        }, 100);
    }

    cancelClicked(): void {
        confirmYesNo("Are you sure you want to cancel this calibration?")
            .then((clicked: ButtonClicked): void => {
                if (clicked === ButtonClicked.Ok) {
                    CalibrationEventSupport.sendEvent(CalibrationEvent.CalibrationCustomerCanceled);
                    Global.MainApp.clearSession();
                    this.$router.push("/");
                }
            });
    }

    getCurrentStatusForListeners(): void {
        CalibrationEventSupport.sendEventStatus({
            Page: "CalibrationVehicle",
        } as DeviceStatusDto);
    }

    mounted(): void {
        SignalR.onGetCurrentStatusForListeners = this.getCurrentStatusForListeners;
        SignalR.onVehicleSelectionByTechnician = this.setVehicleSelectionByTech;
        SignalR.onVehicleSelectionByCompanion = this.setVehicleSelectionByCompanion;

        this.getCurrentStatusForListeners();
        this.reloadDropdowns();
    }

    unmounted(): void {
        SignalR.onGetCurrentStatusForListeners = null;
        SignalR.onVehicleSelectionByTechnician = null;
        SignalR.onVehicleSelectionByCompanion = null;
    }

}
