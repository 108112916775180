

import { Options } from "vue-class-component";

import PageBase from "@/shared/components/common/PageBase";
import HeaderMessage from "@/components/HeaderMessage.vue";

@Options({
    components: {
        HeaderMessage,
    }
})
export default class CalibrationComplete extends PageBase {

    runInstall(): void {
        if (Auggie.PackageDownload.IsAborted()) {
            this.$router.push("/Upgrade/Failed");
            return;
        }
        const err = Auggie.PackageDownload.Install();
        if (err)
            this.$router.push("/Upgrade/Failed");
        else
            this.$router.push("/Upgrade/Installed");
    }

    mounted(): void {
        setTimeout((): void => {
            this.runInstall();
        },100);
    }
}
