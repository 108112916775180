

import { Vue, Options } from "vue-class-component";
import { Modal } from "bootstrap";
import { ButtonClicked } from "@/shared/enums/ButtonClicked";

export interface AlertDialogOptions {
    okButton?: string;
    alt1Button?: string;
    cancelButton?: string;
    largeModal?: boolean;
}

@Options({
})
export default class AlertDialog extends Vue {

    title: string|null = null; // Optional title
    message: string = null!; // Main text content
    okButton: string|null = null!; // Text for confirm button; leave it empty because we don't know what we're using it for
    alt1Button: string|null = null!;
    cancelButton: string|null = null!; // text for cancel button
    largeModal: boolean = false;// larger for oversize text

    modal: Modal|null = null;
    resolvePromise: (value: ButtonClicked|PromiseLike<ButtonClicked>) => void = null!;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rejectPromise: (reason?: any) => void = null!;

    static show(message: string, title?: string, options?: AlertDialogOptions): Promise<ButtonClicked> {
        return AlertDialog.cachedAlertDialog.internalShow(message, title, options);
    }

    private internalShow(message: string, title?: string, options?: AlertDialogOptions): Promise<ButtonClicked> {
        this.title = title ?? null;
        this.message = message || "";
        this.okButton = options?.okButton || null;
        this.alt1Button = options?.alt1Button || null;
        this.cancelButton = options?.cancelButton || null;
        this.largeModal = options?.largeModal || false;
        this.modal = new Modal(document.getElementById("confirmModal")!, {
            backdrop: "static", // A backdrop which doesn't close the modal on click.
            keyboard: true, // Closes the modal when escape key is pressed
            focus: true, // Puts the focus on the modal when initialized.
        });
        this.modal.show();

        // to support multiple modals we need to adjust the backdrop zindex
        const backdrops = document.querySelectorAll(".modal-backdrop.show");
        if (backdrops.length > 1) { // the second backdrop is for this modal
            const backdrop = backdrops[backdrops.length - 1] as HTMLElement;
            backdrop.style.zIndex = "1999";
        }

        // Return promise so the caller can get results
        return new Promise<ButtonClicked>((resolve, reject):void => {
            this.resolvePromise = resolve;
            this.rejectPromise = reject;
        });
    }

    okClicked():void {
        if (!this.modal) return;
        this.modal.hide();
        this.resolvePromise(ButtonClicked.Ok);
    }
    cancelClicked():void {
        if (!this.modal) return;
        this.modal.hide();
        this.resolvePromise(ButtonClicked.Cancel);
    }
    killAlertDialog(): void {
        if (!this.modal) return;
        this.modal.hide();
        this.modal = null;
    }
    alt1Clicked():void {
        if (!this.modal) return;
        this.modal.hide();
        this.resolvePromise(ButtonClicked.Alt1);
    }

    static initializeAlertDialog(dialog: AlertDialog): void {
        AlertDialog.cachedAlertDialog = dialog;
    }
    private static cachedAlertDialog: AlertDialog = null!;

    static killAlertDialogs(): void {
        AlertDialog.cachedAlertDialog.killAlertDialog();
    }
}

export function initializeAlertDialog(dialog: AlertDialog): void {
    AlertDialog.initializeAlertDialog(dialog);
}
export function killAlertDialogs(): void {
    AlertDialog.killAlertDialogs();
}

export function confirmYesNo(message:string, title?: string): Promise<ButtonClicked> {
    return AlertDialog.show(message, title, { okButton: "Yes", cancelButton: "No" });
}

export function confirmOk(message:string, title?: string): Promise<ButtonClicked> {
    return AlertDialog.show(message, title, { okButton: "OK" });
}

export function confirmYesWaitNo(message:string, title?: string): Promise<ButtonClicked> {
    return AlertDialog.show(message, title, { okButton: "Yes", alt1Button: "Wait", cancelButton: "No" });
}

export function showAlertDialog(message:string, title?: string, options?: AlertDialogOptions): Promise<ButtonClicked> {
    return AlertDialog.show(message, title, options);
}

