

import { Vue, Options } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
})
export default class SpinButton extends Vue {

    @Prop({ default: "button" }) readonly type: string = "button";
    @Prop({ default: false }) readonly disabled: boolean = false;
    @Prop({ default: false }) readonly spinning: boolean = false;

}

