

import QrcodeVue from "qrcode.vue";

export default {
    data(): any {
        return {
            value: "",
            size: 250,
            margin: 2,
        };
    },
    components: {
        QrcodeVue,
    },
};

