

import { Vue, Options } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options<ProgressBar>({
    watch: {
        current(val: number): void {
            this.$forceUpdate();
        },
    },
})
export default class ProgressBar extends Vue {

    @Prop({ default: 0 }) readonly minimum: number = 0;
    @Prop({ default: 100 }) readonly maximum: number = 100;
    @Prop({ default: 0 }) readonly current: number = 0;

    getValue(): string {
        let w = "100";
        if (this.maximum)
            w = Math.round((this.current/this.maximum * 100)).toFixed(0);
        return w;
    }

    getStyle(): string {
        return `width: ${this.getValue()}%`;
    }
    getText(): string {
        return `${this.getValue()} %`;
    }
}

