

import ModalBase from "@/shared/components/common/ModalBase";
import Qrcode from "@/components/Qrcode.vue";
import { Options } from "vue-class-component";
import { AUGGIEAVAILABLE } from "@/main";
import Toast from "@/shared/support/Toast";
import { CalibrationEventSupport } from "@/support/CalibrationEvent";
import { CalibrationEvent } from "@/shared/enums/CalibrationEvent";
import { Global } from "@/support/GlobalData";
import { confirmOk } from "@/shared/components/common/AlertDialog.vue";

@Options<HelpModal>({
    components: {
        Qrcode,
    }
})
export default class HelpModal extends ModalBase {

    url = "https://airprodiagnostics.com/auggie-frequently-asked-questions/";

    public static HelpModal: HelpModal|null = null;

    isPrototype(): boolean {
        return Global.IsPrototype();
    }

    show(): void {
        killHelpModal();
        this.showModal();
        this.initialized();
        HelpModal.HelpModal  = this;
    }
    hide():void {
        this.hideModal();
        HelpModal.HelpModal = null;
    }
    assistanceClicked(): void {
        this.loading = true;
        if (AUGGIEAVAILABLE) {
            if (Auggie.TeamViewer.On()) {
                Toast.success("Remote Assistance has been enabled.");
                CalibrationEventSupport.sendEventTvStarted(CalibrationEvent.TeamViewerStarted, Global.DeviceName, null);
            } else
                Toast.warning("Remote Assistance could not be enabled.");
        } else {
            Toast.warning("Remote Assistance not available on development system.");
        }
        this.loading = false;
        this.hide();
    }
    restartClicked(): void {
        if (AUGGIEAVAILABLE) {
            Auggie.System.Restart();
        } else {
            confirmOk("Restart Device clicked - not available on development system");
        }
    }
    cancelClicked():void {
        this.hide();
    }
}

export function killHelpModal(): void {
    if (HelpModal.HelpModal) {
        HelpModal.HelpModal.hide();
        HelpModal.HelpModal = null;
    }
}

