import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_message = _resolveComponent("header-message")
  const _component_power_off = _resolveComponent("power-off")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_header_message, { class: "mt-3 mb-3" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.downloadInfo), 1)
      ]),
      _: 1
    }),
    (_ctx.releaseNotes)
      ? (_openBlock(), _createBlock(_component_header_message, {
          key: 0,
          class: "mb-3 desc"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.releaseNotes), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_power_off)
  ]))
}