export interface SelectionEntry {
    Key: number|string;
    Value: string;
}

export default class SelectionSupport {

    /**
     * Make a selection list for use with <selection> components
     * @param enumType An enumeration type.
     * @param enumTypeDescription An array of displayable strings, each matching an enum value. These are typically defined where the enum is defined.
     * @returns An array of options that can be used by a <selection> component.
     * @example const options = SelectionSupport.EnumSelections(GenericActiveStatus, GenericActiveStatusDescription);
     */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public static EnumSelections(enumType: any, enumTypeDescription: string[]): SelectionEntry[] {
        const options: SelectionEntry[] = [];
        for (const e in enumType) {
            const key = Number(e);
            if (!isNaN(key)) {
                options.push({ Key: key, Value: enumTypeDescription[key]});
            }
        }
        return options;
    }

    public static UniqueOnly(options: SelectionEntry[], ignoreCase?: boolean): SelectionEntry[] {
        if (ignoreCase) {
            return options.filter((ve: SelectionEntry, ie: number, array: SelectionEntry[]): boolean => {
                return array.findIndex((v: SelectionEntry, i: number, aarray: SelectionEntry[]): boolean => { return v.Value.trim().toLowerCase() === ve.Value.trim().toLowerCase(); }) === ie;
            });
        } else {
            return options.filter((ve: SelectionEntry, ie: number, array: SelectionEntry[]): boolean => {
                return array.findIndex((v: SelectionEntry, i: number, aarray: SelectionEntry[]): boolean => { return v.Value.trim() === ve.Value.trim(); }) === ie;
            });
        }
    }

    public static Sort(options: SelectionEntry[]): SelectionEntry[] {
        return options.sort((s1: SelectionEntry, s2: SelectionEntry): number => {
            if (s1.Value > s2.Value) return 1;
            if (s1.Value < s2.Value) return -1;
            return 0;
        });
    }
}
