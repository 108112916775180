

import { Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

export interface SelectionEntry {
    Key: number|string;
    Value: string;
}

export default class Selection extends Vue {

    @Prop({  }) readonly options: SelectionEntry[] = null!;
    @Prop({ default: "", required: true }) readonly modelValue: string|number = "";
    @Prop({ default: false }) readonly readonly: boolean = false;
    @Prop({ default: false }) readonly required: boolean = false;
    @Prop({ default: "none" }) readonly sort: string = "none";
    @Prop({ default: true }) readonly addSelect: boolean = true;

    get selectedKey(): string|number {
        return this.modelValue;
    }
    set selectedKey(key: string|number) {
        this.$emit("update:modelValue", key);
    }

    get prefix(): string {
        return this.required ? "" : " Select";
    }
    get suffix(): string {
        return this.required ? "Required " : "";
    }
    get sortedList(): SelectionEntry[] {
        const arr: SelectionEntry[] = [];
        let sortedArray = arr;
        if (this.options && this.sort) {
            for(const entry of this.options) {
                arr.push(entry);
            }
            sortedArray = arr;
            if (this.sort === "Value") {
                sortedArray = arr.sort((a, b): number => {
                    const x = a.Value.toLowerCase();
                    const y = b.Value.toLowerCase();
                    return x > y ? 1 : y > x ? -1 : 0;
                });
            } else if (this.sort === "Key") {
                sortedArray = arr.sort((a, b): number => {
                    const x = a.Key;
                    const y = b.Key;
                    return x > y ? 1 : y > x ? -1 : 0;
                });
            }
        }
        return sortedArray;
    }

    isMatch(key: string | number): boolean {
        // comparison below deliberatey with type coersion because key may be a string.
        // eslint-disable-next-line eqeqeq       
        return key == this.selectedKey;
    }

    // 
    /**
     * Make a selection list for use with <selection> components
     * @param enumType An enumeration type.
     * @param enumTypeDescription An array of displayable strings, each matching an enum value. These are typically defined where the enum is defined.
     * @returns An array of options that can be used by a <selection> component.
     * @example const options = Selection.EnumSelections(GenericActiveStatus, GenericActiveStatusDescription);
     */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public static EnumSelections(enumType: any, enumTypeDescription: string[]): SelectionEntry[] {
        const options: SelectionEntry[] = [];
        for (const e in enumType) {
            const key = Number(e);
            if (!isNaN(key)) {
                options.push({ Key: key, Value: enumTypeDescription[key]});
            }
        }
        return options;
    }
}

