/**
 * A global class that supports many commonly used functions throughout an application.
 */

import { Vue } from "vue-class-component";

export default class Events {

    public static bubbleEvent(component: Vue, eventName:string, ...args: any[]): void {
        // send the event to all parent components
        for ( ; ; ) {
            component.$emit(eventName, ...args);
            const c = component.$parent;
            if (!c)
                break;
            component = c as any;
        }
    }
}
