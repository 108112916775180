/**
 * A global class that supports toasts throughout an application.
 */

import type App from "@vue/runtime-core";
import Toaster, { createToaster } from "@meforma/vue-toaster";
import Utility from "@/shared/support/Utility";

export enum ToastStatus {
    Info = 1,
    Success = 2,
    Warning = 3,
    Error = 4,
}

export enum FeedbackFormat {
    Close = 0,
    YesNo = 1,
}

interface ButtonInstance {
    EntryNumber: number;
    Toast: any;
    OkButtonCallback: (()=> void)|undefined;
    CancelButtonCallback: (()=> void)|undefined;
    Alt1ButtonCallback: (()=> void)|undefined;
}

export default class Toast {

    private static AppObject: App.App<Element>;

    // Toast implementation (based on https://github.com/MeForma/vue-toaster)

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public static info(message:string, title?: string, encoded?: boolean, options?: any): any { return Toast.toast(ToastStatus.Info, message, title, encoded, options); }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public static success(message:string, title?: string, encoded?: boolean, options?: any): any { return Toast.toast(ToastStatus.Success, message, title, encoded, options); }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public static warning(message:string, title?: string, encoded?: boolean, options?: any): any { return Toast.toast(ToastStatus.Warning, message, title, encoded, options); }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public static error(message:string, title?: string, encoded?: boolean, options?: any): any { return Toast.toast(ToastStatus.Error, message, title, encoded, options); }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static clear(): void { (Toast.AppObject as any).$toast.clear(); }

    private static ToastDefaults: any = { position: "top-right", };

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public static toast(status: ToastStatus, message: string, title?: string, encoded?: boolean, options?: any): any {

        if (status === ToastStatus.Error)
            console.error(message);
        else if (status === ToastStatus.Warning)
            console.warn(message);

        if (!encoded)
            message = Utility.encodeHTML(message);
        if (title) {
            if (!encoded)
                title = Utility.encodeHTML(title);
            message = `<b>${title}</b><hr/>${message}`;
        }
        if (!options)
            options = { };
        const opts: any = {};
        Object.assign(opts, this.ToastDefaults, options);
        opts.message = message;
        const toaster = createToaster({ options });

        switch (status) {
            default:
            case ToastStatus.Info:
                opts.type = "info";
                break;
            case ToastStatus.Success:
                opts.type = "success";
                break;
            case ToastStatus.Warning:
                opts.type = "warning";
                break;
            case ToastStatus.Error:
                opts.type = "error";
                break;
        }
        return toaster.show(message, opts);
    }

    /**
     * Renders a list of errors as an error toast.
     * @param messages List of errors.
     * @param title An optional title.
     * @returns
     */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public static errorList(messages:string[], title?: string, options?: any): void {
        if (messages.length === 0) return;
        let msg = "";
        for (const m of messages) {
            if (msg)
                msg += "<br/>";
            msg += Utility.encodeHTML(m);
        }
        Toast.toast(ToastStatus.Error, msg, title, true, options);
    }

    /**
     * Used to initialize toast support for the entire application.
     * @param app The application.
     */
    public static initializeToaster(app: App.App<Element>): void {
        Toast.AppObject = app;
        app.use(Toaster, { position: "top-right" });
    }
}

export class ToastFeedback {

    static counter = 1;
    static Buttons: ButtonInstance[] = [];

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public static info(format: FeedbackFormat, message:string, title?: string, encoded?: boolean, options?: any, okButtonCb?: ()=> void, cancelButtonCb?: ()=> void, alt1ButtonCb?: ()=> void): number {
        return ToastFeedback.toast(format, ToastStatus.Info, message, title, encoded, options, okButtonCb, cancelButtonCb, alt1ButtonCb);
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public static success(format: FeedbackFormat, message:string, title?: string, encoded?: boolean, options?: any, okButtonCb?: ()=> void, cancelButtonCb?: ()=> void, alt1ButtonCb?: ()=> void): number {
        return ToastFeedback.toast(format, ToastStatus.Success, message, title, encoded, options, okButtonCb, cancelButtonCb, alt1ButtonCb);
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public static warning(format: FeedbackFormat, message:string, title?: string, encoded?: boolean, options?: any, okButtonCb?: ()=> void, cancelButtonCb?: ()=> void, alt1ButtonCb?: ()=> void): number {
        return ToastFeedback.toast(format, ToastStatus.Warning, message, title, encoded, options, okButtonCb, cancelButtonCb, alt1ButtonCb);
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public static error(format: FeedbackFormat, message:string, title?: string, encoded?: boolean, options?: any, okButtonCb?: ()=> void, cancelButtonCb?: ()=> void, alt1ButtonCb?: ()=> void): number {
        return ToastFeedback.toast(format, ToastStatus.Error, message, title, encoded, options, okButtonCb, cancelButtonCb, alt1ButtonCb);
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public static toast(format: FeedbackFormat, status: ToastStatus, message: string, title?: string, encoded?: boolean, options?: any,
        okButtonCb?: ()=> void, cancelButtonCb?: ()=> void, alt1ButtonCb?: ()=> void): number {

        // eslint-disable-next-line
        (window as any).ToastOkClicked = ToastFeedback.ToastOkClicked;
        // eslint-disable-next-line
        (window as any).ToastCancelClicked = ToastFeedback.ToastCancelClicked;
        // eslint-disable-next-line
        (window as any).ToastAlt1Clicked = ToastFeedback.ToastAlt1Clicked;

        const opts: any = { dismissible: false, duration: false, onClose: ToastFeedback.clearButtonInstance(ToastFeedback.counter), };
        Object.assign(opts, options||{}, opts);

        const toast = Toast.toast(status, this.getFormat(format, message, okButtonCb, cancelButtonCb, alt1ButtonCb), title, true, opts);

        ToastFeedback.Buttons.push({ EntryNumber: ToastFeedback.counter, Toast: toast, OkButtonCallback: okButtonCb, CancelButtonCallback: cancelButtonCb, Alt1ButtonCallback: alt1ButtonCb });
        return ToastFeedback.counter++;
    }

    public static clear(num: number): void {
        console.debug("Toast: Clearing " + num);
        const button = ToastFeedback.getButton(num);
        if (!button) return;
        ToastFeedback.clearButtonInstance(num);
        button.Toast.destroy();
    }

    /**
     * Clear all toasts with feedback (sticky toasts)
     */
    public static clearAll(): void {
        for (const button of ToastFeedback.Buttons) {
            button.Toast.destroy();
        }
        ToastFeedback.Buttons = [];
    }

    public static ToastOkClicked(num: number): void {
        console.debug("Toast: OK Clicked " + num);
        const button = ToastFeedback.getButton(num);
        if (!button) return;
        if (button.OkButtonCallback)
            button.OkButtonCallback();
        ToastFeedback.clearButtonInstance(num);
        button.Toast.destroy();
    }
    public static ToastCancelClicked(num: number): void {
        console.debug("Toast: Cancel Clicked " + num);
        const button = ToastFeedback.getButton(num);
        if (!button) return;
        if (button.CancelButtonCallback)
            button.CancelButtonCallback();
        ToastFeedback.clearButtonInstance(num);
        button.Toast.destroy();
    }
    public static ToastAlt1Clicked(num: number): void {
        console.debug("Toast: Alt1 Clicked " + num);
        const button = ToastFeedback.getButton(num);
        if (!button) return;
        if (button.Alt1ButtonCallback)
            button.Alt1ButtonCallback();
        ToastFeedback.clearButtonInstance(num);
        button.Toast.destroy();
    }
    private static getButton(num: number) : ButtonInstance|null {
        const buttons = ToastFeedback.Buttons.filter((v: ButtonInstance, i:number, arr: ButtonInstance[]): boolean => { return v.EntryNumber === num; });
        if (buttons.length === 0) {
            console.debug(`Toast: Toast ${num} not found`);
            return null;
        }
        return buttons[0];
    }
    private static clearButtonInstance(num: number): void {
        console.debug(`Toast: Removing Button ${num}`);
        ToastFeedback.Buttons = ToastFeedback.Buttons.filter((v: ButtonInstance, i:number, arr: ButtonInstance[]): boolean => { return v.EntryNumber !== num; });
        console.debug(`Toast: Remaining Buttons ${ToastFeedback.Buttons.length}`);
    }
    static getFormat(format: FeedbackFormat, message: string,
        okButtonCb: (()=> void)|undefined, cancelButtonCb: (()=> void)|undefined, alt1ButtonCb: (()=> void)|undefined): string {
        let buttons = "";
        switch (format) {
            case FeedbackFormat.Close:
                buttons =  `<button id="toast-btn-${ToastFeedback.counter}" class="btn btn-xs btn-info" onclick="window.ToastOkClicked(${ToastFeedback.counter});">
                                Close
                            </button>`;
                if (okButtonCb) document.addEventListener(`toast-close-${ToastFeedback.counter}`, okButtonCb);
                break;
            case FeedbackFormat.YesNo:
                buttons =  `<button id="toast-btn-${ToastFeedback.counter}" class="btn btn-xs btn-info" onclick="window.ToastOkClicked(${ToastFeedback.counter});">
                                Yes
                            </button>
                            <button id="toast-btn-${ToastFeedback.counter}" class="btn btn-xs btn-info" onclick="window.ToastCancelClicked(${ToastFeedback.counter});">
                                No
                            </button>`;
                if (okButtonCb) document.addEventListener(`toast-ok-${ToastFeedback.counter}`, okButtonCb);
                if (cancelButtonCb) document.addEventListener(`toast-cancel-${ToastFeedback.counter}`, cancelButtonCb);
                break;
            default:
                throw new Error(`Invalid ToastFeedback format ${format}`);
        }
        return `<div class="toast-div">
                    <div class="toast-buttons">
                        ${buttons}
                    </div>
                    <div class="toast-msg">
                        ${message}
                    </div>
                </div>`;
    }
}
