import { createVNode as _createVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "global-simple-keyboard",
  ref: "simplekeyboarddivref"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", null, [
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createVNode("div", _hoisted_1, [
        _createVNode("div", { class: _ctx.keyboardClass }, null, 2)
      ], 512)
    ]))
  ]))
}