import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_button_button = _resolveComponent("button-button")
  const _component_fa_icon = _resolveComponent("fa-icon")

  return (_openBlock(), _createBlock("div", {
    disabled: _ctx.disabled || _ctx.spinning
  }, [
    _withDirectives(_createVNode(_component_button_button, {
      type: "button",
      class: "btn-close"
    }, null, 512), [
      [_vShow, !_ctx.disabled && !_ctx.spinning]
    ]),
    _withDirectives(_createVNode("div", null, [
      _createVNode(_component_fa_icon, {
        icon: "spinner",
        class: "fa-spin me-2"
      })
    ], 512), [
      [_vShow, _ctx.spinning]
    ])
  ], 8, ["disabled"]))
}