import { renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("button", {
    type: "button",
    class: _ctx.getCss(),
    onMousedown: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.mousedown && _ctx.mousedown(...args)))
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 34))
}