

import { Vue, Options } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
})
export default class FormRow extends Vue {

    @Prop({ default: 4 }) readonly labelWidth: number = 4;
    @Prop({ default: 0 }) readonly fieldWidth: number = 0;
    @Prop({ default: "" }) readonly labelCss: string = "";
    @Prop({ default: "" }) readonly fieldCss: string = "";
    @Prop({ default: "mb-2" }) readonly rowCss: string = "mb-2";

    getRowCss(): string {
        return `row ${this.rowCss}`;
    }
    getLabelCss(): string {
        return `col-lg-${this.labelWidth.toString()} col-form-label${this.labelCss? " "+this.labelCss : ""}`;
    }
    getFieldsCss(): string {
        let fieldWidth = this.fieldWidth;
        if (!fieldWidth)
            fieldWidth = 12-this.labelWidth;
        return `col-lg-${fieldWidth}${this.fieldCss? " "+this.fieldCss : ""}`;
    }
}

