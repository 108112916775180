

import { Options } from "vue-class-component";

import PageBase from "@/shared/components/common/PageBase";
import HeaderMessage from "@/components/HeaderMessage.vue";
import PowerOff from "@/components/PowerOff.vue";
import { Global } from "@/support/GlobalData";

@Options({
    components: {
        HeaderMessage,
        PowerOff,
    }
})
export default class UpgradeRequired extends PageBase {

    get releaseNotes(): string {
        if (!Global.PackageUpgrade) return "";
        return Global.PackageUpgrade?.ReleaseNotes;
    }

    proceedClicked(): void {
        if (Auggie.PackageDownload.IsAborted()) {
            this.$router.push("/Upgrade/Failed");
        } else {
            this.$router.push("/Upgrade/Download");
        }
    }
}
