import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "alert alert-danger" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")

  return (_openBlock(), _createBlock("div", null, [
    _withDirectives(_createVNode("div", _hoisted_1, [
      _createVNode(_component_fa_icon, {
        icon: "spinner",
        class: "fa-spin me-2"
      }),
      _createTextVNode(_toDisplayString(_ctx.text), 1)
    ], 512), [
      [_vShow, _ctx.loading]
    ])
  ]))
}