

import { Vue, Options } from "vue-class-component";
import { SignalR } from "./support/SignalR";
import Header from "@/components/Header.vue";
import AlertDialog, { confirmOk, initializeAlertDialog } from "@/shared/components/common/AlertDialog.vue";
import { EMPTY, Observable } from "rxjs";
import { AUGGIEAVAILABLE } from "./main";
import Home from "./views/Home.vue";
import { CalibrationSessionDto } from "@/shared/models/CalibrationSessionDto";
import CameraViewerConfiguration from "@/shared/models/CameraViewerConfiguration";
import { Global } from "./support/GlobalData";
import { DataRequest } from "@/shared/support/Data";
import PageBase from "./shared/components/common/PageBase";
import { killHelpModal } from "./views/HelpModal.vue";
import { UpdateResultDto } from "./shared/models/UpdateResultDto";

@Options({
    components: {
        Header,
        AlertDialog,
    },
    subscriptions(): any {
        if (!AUGGIEAVAILABLE) return []; // debugging stand-alone only
        return {
            auggieNetworkConnected$: Auggie.Network.Connected
        };
    },
})
export default class App extends Vue {

    mounted(): void {
        initializeAlertDialog(this.$refs.alertDialog);

        if (AUGGIEAVAILABLE) {
            Global.DeviceSerialNumber = Auggie.Device.SerialNumber();
            Global.Environment = Auggie.System.GetEnvironment();
        } else {
            Global.DeviceSerialNumber = "EDkWCuGSYz7W9KgpABTz"; // default test device
            Global.Environment = "DEV";
        }

        // Shut down TeamViewer
        if (AUGGIEAVAILABLE && Global.IsVersionAtLeast("2022.286.2")) {
            Auggie.TeamViewer.Off();
        }

        // Init signalr
        SignalR.start(Global.GetSignalRFunctionUrl());

        DataRequest.getWebAPI = (): string => {
            const domain = Global.GetWebAPIUrl();
            if (!domain) throw new Error("Global.GetWebAPIUrl() didn't return a URL");
            return domain;
        };
        DataRequest.setRequestHeaders = (request: XMLHttpRequest, useAuthentication: boolean): void => {
            request.setRequestHeader("Device-Api-Version", "v1.0");
            request.setRequestHeader(process.env.VUE_APP_DEVICE_SERIAL_NUMBER_HEADER!, Global.DeviceSerialNumber);
            request.setRequestHeader("Authorization", `Bearer ${DataRequest.getToken!()}`);
        };
        DataRequest.getToken = (): string => {
            let token: string;
            if (AUGGIEAVAILABLE) {
                token = Auggie.System.GetToken();
            } else {
                token = process.env.VUE_APP_WEBAPITOKEN || "no token";
            }
            return token;
        };
        DataRequest.haveToken = (): boolean => {
            return true;
        };
        DataRequest.getMaxRetries = (): number => {
            return 10; // 10 retries
        };
        DataRequest.getDetailLog = (): boolean => {
            return Global.DetailLog;
        };

        PageBase.NewPageShownCallback = (): void => {
            killHelpModal();
        };

        this.updateBatteryLevel();
        setInterval((): void => {
            this.updateBatteryLevel();
        }, 60*1000);

        this.setSessionVerification();

        SignalR.onRestartCalibrationFallback = (): void => {
            if (Global.CalibrationSession != null)
                this.$router.push("/Calibration/CameraReady");
        };
    }

    public created(): void {
        Global.MainApp = this;
    }

    // Session Management

    public setSession(session: CalibrationSessionDto): void {
        Global.CalibrationSession = session;
    }
    public setCameraConfig(cameraConfig: CameraViewerConfiguration): void {
        Global.CameraViewerConfiguration = cameraConfig;
        // save the original windshield if this is the first time
        if (Global.OriginalWindshieldAngle == null)
            Global.OriginalWindshieldAngle = cameraConfig.CarWindshieldAngle;
    }
    public clearSession(): void {
        if (AUGGIEAVAILABLE) {
            Auggie.Target.ClearTarget();
            if (Global.IsVersionAtLeast("0.9.15"))
                Auggie.Lights.SetIntensity(0);
            else
                Auggie.Camera.LightOff();
            Auggie.Camera.TerminateRender();
        } else {
            // nothing to do
        }
        Global.clear();
    }
    public haveSession(): boolean {
        return !!Global.CalibrationSession && Global.CalibrationSession.SessionStartDt != null;
    }
    public setDemoSession(): void {
        Global.CalibrationSession = this.getFakeDemoSession();
        Global.CalibrationSession.SessionStartDt = null;// not a real session yet
    }
    public getFakeDemoSession(): CalibrationSessionDto {
        return {
            CalibrationSessionGuid: "000000000-0000-0000-0000-000000000000",
            SessionStartDt: new Date().toUTCString(),
            CreatedDt: new Date().toUTCString(),
            WheelArchHeightEnabledInd: true,
        } as CalibrationSessionDto;
    }

    setSessionVerification(): void {
        if (Global.IsDemo()) return;
        if (!this.verificationInterval) {
            const interval = Number(process.env.VUE_APP_VERIFYSESSIONINTERVAL!);
            if (!interval) return;
            if (Global.DetailLog) console.log(`setSessionVerification: Session verification every ${interval} seconds`);
            this.verificationInterval = setInterval((): void => {
                this.verifySession();
            }, interval * 1000) as unknown as number;
        }
    }
    clearSessionVerification(): void {
        if (this.verificationInterval) {
            clearInterval(this.verificationInterval);
            this.verificationInterval = 0;
        }
    }
    verifySession(): void {
        // verify that the session exists. Auto-retry is deliberately turned off as failures are handled elsewhere and
        // may be a recoverable error. We're strictly testing for existance/non-existance of the session.
        if (Global.CalibrationSession === null || Global.CalibrationSession.CalibrationSessionGuid === null) return;
        const dr = new DataRequest(undefined, undefined, 0);// no retries
        dr.autoToastOnFailure = false;// don't show failure popup
        dr.$get<UpdateResultDto>("/Service/CalibrationSession/VerifySession", { CalibrationSessionGuid: Global.CalibrationSession!.CalibrationSessionGuid })
            .then((updateResult: UpdateResultDto): void => {
                if (!updateResult.Success) {
                    if (Global.DetailLog) console.log(`verifySession: Session ${Global.CalibrationSession!.CalibrationSessionGuid} does NOT exist`);
                    Global.MainApp.clearSession();
                    this.$router.push("/");
                    confirmOk("The calibration session is no longer valid.");
                } else {
                    if (Global.DetailLog) console.log(`verifySession: Session ${Global.CalibrationSession!.CalibrationSessionGuid} exists`);
                }
            })
            .catch((reason: any): void => {
                // we still don't know, ignore this failure
            });
    }

    verificationInterval = 0;

    // Main App accessors

    public static get MainApp(): App {
        return Global.MainApp;
    }

    public static get MainHeader(): Header {
        return Header.MainHeader;
    }

    public static get MainHome(): Home {
        return Global.MainHome;
    }

    // C# Bridge - Battery, Network

    auggieNetworkConnected$ = EMPTY as Observable<boolean>;

    updateBatteryLevel(): void {
        if (!AUGGIEAVAILABLE)
            this.batteryLevel = 0.6; // debugging stand-alone only
        else {
            this.batteryLevel = Auggie.Device.BatteryLevel();
        }
    }
    get batteryLevel(): number {
        return this.batteryLevelCache;
    }
    set batteryLevel(level: number) {
        this.batteryLevelCache = level;
    }
    private batteryLevelCache = 0;

    get networkConnected(): boolean {
        if (!AUGGIEAVAILABLE) return true; // debugging stand-alone only
        if (this.auggieNetworkConnected$ === null) return false;
        return this.auggieNetworkConnected$ as unknown as boolean;
    }

    declare $refs: {
        alertDialog: AlertDialog,
        header: Header,
    }
}

