

import { Options } from "vue-class-component";

import PageBase from "@/shared/components/common/PageBase";
import HeaderMessage from "@/components/HeaderMessage.vue";
import PowerOff from "@/components/PowerOff.vue";
import { Global } from "@/support/GlobalData";

@Options({
    components: {
        HeaderMessage,
        PowerOff,
    }
})
export default class UpgradeDownload extends PageBase {

    timerInterval: number = 0;
    progress: number = 0;

    get releaseNotes(): string {
        if (!Global.PackageUpgrade) return "";
        return Global.PackageUpgrade?.ReleaseNotes;
    }

    get downloadInfo(): string {
        if (!this.progress) return "Downloading Upgrade";
        return `Download in Progress - ${this.progress.toFixed(0)}%`;
    }

    setProgress(): void {
        this.progress = Auggie.PackageDownload.Progress();
        if (Auggie.PackageDownload.IsDone()) {
            this.clearInterval();
            this.$router.push("/Upgrade/Installing");    
        } else if (Auggie.PackageDownload.IsAborted()) {
            this.clearInterval();
            this.$router.push("/Upgrade/Failed");
        }
    }

    clearInterval(): void {
        if (this.timerInterval) {
            clearInterval(this.timerInterval);
            this.timerInterval = 0;
        }
    }

    mounted(): void {
        this.timerInterval = setInterval((): void => {
            this.setProgress();
        }, 1500) as unknown as number;
    }

    unmounted(): void {
        this.clearInterval();
    }

}
