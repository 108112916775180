/* eslint-disable @typescript-eslint/no-explicit-any*/
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { State } from "@progress/kendo-data-query";
import Toast from "./Toast";
import ChunkedUploadDto from "../models/ChunkedUploadDto";
import ChunkedUploadResponseDto from "../models/ChunkedUploadResponseDto";
import md5 from "blueimp-md5";

/**
 * Grid data request
 */
export interface DataResult<T=any> {
    data: T[];
    total: number;
}

export interface GridResult<T> {
    Rows: T,
    Total: number;
}

export class DataRequest {

    private useAuthentication: boolean;
    private domain: string;
    private maxRetries: number;
    private currRetries = 0;
    private readonly RetryTime = 2*1000; // 2 seconds
    private detailLog = false;
    private startRequest = 0;// start time of any request

    public static Enabled = true; // debugging, turn off to disable apis

    /**
     * Constructor.
     * @param useAuthentication Defines whether authentication is used for all requests. The default is true.
     * @param domain Defines the domain (https://.....) for this request. The default is the domain defined by the DataRequest.getWebAPI callback.
     * @param retries Defines the number of retries to attempt. 0 means no retries. The default is the value returned by the optional DataRequest.getMaxRetries callback.
     */
    public constructor(useAuthentication: boolean = true, domain: string = "", retries: number = 0) {
        this.useAuthentication = useAuthentication;
        this.domain = domain;
        this.maxRetries = retries;
        if (this.maxRetries === 0 && DataRequest.getMaxRetries)
            this.maxRetries = DataRequest.getMaxRetries();
        if (!this.domain) {
            if (!DataRequest.getWebAPI) throw new Error("DataRequest.getWebAPI must be set");
            this.domain = DataRequest.getWebAPI();
            if (!this.domain.endsWith("/"))
                this.domain += "/";
        }
        this.detailLog = false;
        if (DataRequest.getDetailLog)
            this.detailLog = DataRequest.getDetailLog();
        if (!this.domain.endsWith("/"))
            throw new Error(`Domain must end in / - Got ${this.domain}`);
    }

    public static getWebAPI: (() => string)|null = null;

    public static getToken: (() => string)|null = null;
    public static haveToken: (() => boolean)|null = null;
    public static setRequestHeaders: ((request: XMLHttpRequest, useAuthentication: boolean) => void)|null = null;
    public static getMaxRetries: (() => number )|null = null;
    public static getDetailLog: (() => boolean )|null = null;

    /**
     * Returns whether a toast is automatically shown when a request fails. The default is true.
     */
    get autoToastOnFailure(): boolean {
        return this.toastOnFailure;
    }
    /**
     * Defines whether a toast is automatically shown when a request fails. The default is true.
     */
    set autoToastOnFailure(show: boolean) {
        this.toastOnFailure = show;
    }
    private toastOnFailure: boolean = true;

    private setStandardHeaders(request: XMLHttpRequest): void {
        request.setRequestHeader("X-Requested-With", "XMLHttpRequest");
        request.setRequestHeader("Cache-Control", "no-cache");
        if (DataRequest.setRequestHeaders)
            DataRequest.setRequestHeaders(request, this.useAuthentication);
    }

    /**
     * Generic GET with query string.
     * @param url Requested URL including query string.
     * @param query An optional object with additional query string arguments. Example: { id: 10, year: 2011, make: "Ford"}.
     * @returns Promise, data returned from URL.
     * @template TOut The type of data returned.
     */
    public $get<TOut=any>(url: string, query?: any): Promise<TOut> {
        const promise = new Promise<TOut>((resolve, reject):void => {
            this.getInternal<TOut>(this.getUrl(url, query), resolve, reject);
        });
        return promise;
    }

    private getInternal<TOut=any>(url: string, resolvePromise: (value: TOut) => void, rejectPromise:(reason?: any) => void): void {
        const request: XMLHttpRequest = new XMLHttpRequest();
        request.open("GET", url, true);
        this.setStandardHeaders(request);
        request.onreadystatechange = (ev: Event): any => {
            if (!this.handleResponse<TOut>(request, resolvePromise, rejectPromise)) {
                setTimeout((): void => {
                    this.getInternal<TOut>(url, resolvePromise, rejectPromise);
                }, this.RetryTime);// try again
            }
        };
        if (this.detailLog) console.log(`GET ${url}`);
        this.startRequest = performance.now();
        request.send();
    }

    /**
     * Generic GET with query string, without response handling.
     * @param url Requested URL including query string.
     * @param query An optional object with additional query string arguments. Example: { id: 10, year: 2011, make: "Ford"}.
     * @returns Promise, data returned from URL.
     */
    public $getPure(url: string, query?: any): Promise<string> {
        const promise = new Promise<string>((resolve, reject): void => {
            this.getPureInternal(this.getUrl(url, query), resolve, reject);
        });
        return promise;
    }
    private getPureInternal(url: string, resolvePromise: (value: string) => void, rejectPromise:(reason?: any) => void): void {
        const request: XMLHttpRequest = new XMLHttpRequest();
        request.open("GET", url, true);
        request.setRequestHeader("X-Requested-With", "XMLHttpRequest");
        request.setRequestHeader("Cache-Control", "no-cache");
        request.onreadystatechange = (ev: Event): any => {
            if (request.readyState === XMLHttpRequest.DONE) {
                if (!DataRequest.Enabled) {
                    console.error("$getPure rejected, disabled via debug feature");
                    rejectPromise(request.status);
                    return;
                }
                if (request.status === 200) {
                    if (this.detailLog) {
                        const end = performance.now();
                        const ms = end - this.startRequest;
                        console.log(`Request Response SUCCESS: (${ms.toFixed(2)}ms) XMLHttpRequest:${JSON.stringify(request)}`);
                    }
                    resolvePromise(request.responseText);
                } else {
                    if (this.detailLog) {
                        const end = performance.now();
                        const ms = end - this.startRequest;
                        console.log(`Request Response FAILURE: (${ms.toFixed(2)}ms) retries: ${this.maxRetries ? this.currRetries : "max"} XMLHttpRequest:${JSON.stringify(request)}`);
                    }
                    if (this.maxRetries && ++this.currRetries < this.maxRetries) {
                        setTimeout((): void => {
                            this.getPureInternal(url, resolvePromise, rejectPromise);// try again
                        }, this.RetryTime);// try again
                        return false;
                    }
                    console.error(`$getPure error: ${request.status}`);
                    rejectPromise(request.status);
                }
            }
        };
        if (this.detailLog) console.log(`GET ${url}`);
        this.startRequest = performance.now();
        request.send();
    }

    /**
     * Generic POST with optional query string and optional data.
     * @param url Requested URL including optional query string.
     * @param query An optional object with additional query string arguments. Example: { id: 10, year: 2011, make: "Ford"}.
     * @param dataIn Optional JSON data passed in request body.
     * @returns Promise, data returned from URL.
     * @template TIn The type of input data.
     * @template TOut The type of data returned.
     */
    public $post<TIn=any, TOut=any>(url: string, query?: any, dataIn?: TIn | null): Promise<TOut> {
        const promise = new Promise<TOut>((resolve, reject):void => {
            this.postInternal<TIn, TOut>(this.getUrl(url, query), dataIn, resolve, reject);
        });
        return promise;
    }

    private postInternal<TIn=any, TOut=any>(url: string, dataIn: TIn|null|undefined, resolvePromise: (value: TOut) => void, rejectPromise:(reason?: any) => void): void {
        const request: XMLHttpRequest = new XMLHttpRequest();
        request.open("POST", url, true);
        this.setStandardHeaders(request);
        request.setRequestHeader("Content-Type", "application/json");
        request.onreadystatechange = (ev: Event): any => {
            if (!this.handleResponse(request, resolvePromise, rejectPromise)) {
                setTimeout((): void => {
                    this.postInternal<TIn, TOut>(url, dataIn, resolvePromise, rejectPromise);
                }, this.RetryTime);// try again
            }
        };
        const d = dataIn ? JSON.stringify(dataIn) : null;
        if (this.detailLog) console.log(`POST ${url} ${d}`);
        this.startRequest = performance.now();
        request.send(d);
    }

    /**
     * POST Blob with query string
     * @param url Requested URL including optional query string.
     * @param query A object with additional query string arguments. Example: { id: 10, year: 2011, make: "Ford"}.
     * @param dataIn A Blob object.
     * @returns Promise, data returned from URL.
     * @template TOut The type of data returned.
     */
    public $postBlob<TOut=any>(url: string, query: any, dataIn: Blob, extraData?: any): Promise<TOut> {
        const promise = new Promise<TOut>((resolve, reject):void => {
            const formData = new FormData();
            formData.append("file", dataIn);
            if (extraData) {
                for (const key in extraData) {
                    formData.append(key, extraData[key]);
                }
            }
            this.postBlobInternal<TOut>(this.getUrl(url, query), formData, resolve, reject);
        });
        return promise;
    }

    private postBlobInternal<TOut=any>(url: string, formData: FormData, resolvePromise: (value: TOut) => void, rejectPromise:(reason?: any) => void): void {
        const request: XMLHttpRequest = new XMLHttpRequest();
        request.open("POST", url, true);
        this.setStandardHeaders(request);
        request.onreadystatechange = (ev: Event): any => {
            if (!this.handleResponse(request, resolvePromise, rejectPromise)) {
                setTimeout((): void => {
                    this.postBlobInternal<TOut>(url, formData, resolvePromise, rejectPromise);
                }, this.RetryTime);// try again
            }
        };
        if (this.detailLog) console.log(`POST BLOB ${url}`);
        this.startRequest = performance.now();
        request.send(formData);
    }

    /**
     * Generic DELETE with optional query string.
     * @param url Requested URL including optional query string.
     * @param query An optional object with additional query string arguments. Example: { id: 10, year: 2011, make: "Ford"}.
     * @returns Promise, data returned from URL.
     * @template TOut The type of data returned.
     */
    public $delete<TOut=any>(url: string, query?: any): Promise<TOut> {
        const promise = new Promise<TOut>((resolve, reject):void => {
            this.deleteInternal<TOut>(this.getUrl(url, query), resolve, reject);
        });
        return promise;
    }

    private deleteInternal<TOut=any>(url: string, resolvePromise: (value: TOut) => void, rejectPromise:(reason?: any) => void): void {
        const request: XMLHttpRequest = new XMLHttpRequest();
        request.open("DELETE", url, true);
        this.setStandardHeaders(request);
        request.onreadystatechange = (ev: Event): any => {
            if (!this.handleResponse(request, resolvePromise, rejectPromise)) {
                setTimeout((): void => {
                    this.deleteInternal<TOut>(url, resolvePromise, rejectPromise);
                }, this.RetryTime);// try again
            }
        };
        if (this.detailLog) console.log(`DELETE ${url}`);
        this.startRequest = performance.now();
        request.send();
    }

    /**
     * Generic File POST with optional query string and file data.
     * @param url Requested URL including optional query string.
     * @param query An optional object with additional query string arguments. Example: { id: 10, year: 2011, make: "Ford"}.
     * @param file A file to transfer.
     * @param progressCallback An optional callback which is called during the upload. Typically used for progress information.
     * @returns Promise, data returned from URL.
     * @template TOut The type of data returned.
     */
    public $postFile<TOut=any>(url: string, query: any|null, file: File, fileName: string, progressCallback?: (ev: ProgressEvent) => any|null): Promise<TOut> {
        const promise = new Promise<TOut>((resolve, reject):void => {
            const formData = new FormData();
            formData.append("file", file, fileName.toLowerCase());
            this.postFileInternal<TOut>(this.getUrl(url, query), formData, progressCallback, resolve, reject);
        });
        return promise;
    }

    private postFileInternal<TOut=any>(url: string, formData: FormData, progressCallback: ((ev: ProgressEvent) => any|null)|undefined, resolvePromise: (value: TOut) => void, rejectPromise:(reason?: any) => void): void {
        const request: XMLHttpRequest = new XMLHttpRequest();
        request.open("POST", url, true);
        this.setStandardHeaders(request);
        //request.setRequestHeader("Content-Type", "multipart/form-data"); // note that specifying content-type will fail
        request.onreadystatechange = (ev: Event): any => {
            if (!this.handleResponse(request, resolvePromise, rejectPromise)) {
                setTimeout((): void => {
                    this.postFileInternal<TOut>(url, formData, progressCallback, resolvePromise, rejectPromise);
                }, this.RetryTime);// try again
            }
        };
        if (progressCallback)
            request.upload.onprogress = progressCallback;
        if (this.detailLog) console.log(`POST FILE ${url}`);
        this.startRequest = performance.now();
        request.send(formData);
    }

    private getUrl(url: string, query?: any): string {
        if (url.startsWith("/"))
            url = url.substring(1);
        url = `${this.domain}${url}`;

        if (query) {

            let hasQm = url.indexOf("?") >= 0;
            let args = "";
            for (const key in query) {
                args += hasQm ? "&" : "?";
                hasQm = true;
                const val = query[key];
                if (val !== undefined && val !== null)
                    args += `${encodeURIComponent(key)}=${encodeURIComponent(val)}`;
                else
                    args += `${encodeURIComponent(key)}=`;
            }
            url += args;
        }
        return url;
    }

    private handleResponse<TOut>(request: XMLHttpRequest, resolvePromise: (value: TOut) => void, rejectPromise: (reason?: any) => void): boolean {
        if (request.readyState === XMLHttpRequest.DONE) {
            if (!DataRequest.Enabled) {
                const msg = "APIs disabled via debug feature";
                if (this.autoToastOnFailure)
                    Toast.error(msg); // show error message
                console.error(`handleResponse error: ${msg}`);
                rejectPromise(msg);
            }
            if (request.status === 200) {
                let result: any = null;
                if (request.responseText)
                    result = JSON.parse(request.responseText);
                if (this.detailLog) {
                    const end = performance.now();
                    const ms = end - this.startRequest;
                    console.log(`Request Response SUCCESS: (${ms.toFixed(2)}ms) XMLHttpRequest:${JSON.stringify(request)} Response:${request.responseText}`);
                }
                resolvePromise(result);
            } else {
                if (this.detailLog) {
                    const end = performance.now();
                    const ms = end - this.startRequest;
                    console.log(`Request Response FAILURE: (${ms.toFixed(2)}ms) retries: ${this.maxRetries ? this.currRetries : "max"} XMLHttpRequest:${JSON.stringify(request)}`);
                }
                if (this.maxRetries && ++this.currRetries < this.maxRetries)
                    return false;
                let error: string;
                if (request.status === 404) {
                    error = `Not Found: ${request.status}`;
                } else if (request.status === 409) {
                    error = `Server error: ${request.responseText} - ${request.status}`;
                } else if (request.status >= 400 && request.status <= 499) {
                    error = `Not Authorized: ${request.status}`;
                } else if (request.status === 0) {
                    // Currently a CORS request (which this always is) which crashes with a 500 HTTP result code will actually
                    // return 0. Once the server authentication and CORS code is finalized we may need to change this error message
                    // to be slightly more descriptive. In a non-CORS environment, 500 will be reflected as status 500. And 0
                    // literally means that the server connection was lost.
                    // When 0 is returned we never get a responseText.
                    error = "The server failed with an unexpected error or the server connection was lost";
                } else if (request.status === 503) {
                    error = "Service Not Available - Please Try Again Later";
                } else {
                    error = `Server error: ${request.responseText} - ${request.status}`;
                }
                if (this.autoToastOnFailure)
                    Toast.error(error); // show error message
                console.error(`handleResponse error: ${error}`);
                rejectPromise(error);
            }
        }
        return true;
    }

    /**
     * Used to load grid data.
     * @param url The URL providing the grid data.
     * @param dataState The state of the grid.
     * @param search An optional search string.
     * @param query An optional object with additional query string arguments. Example: { id: 10, year: 2011, make: "Ford"}.
     * @returns A Promise.
     * @template TOut The type of data returned as grid data, usually an array.
     */
    public $getGridData<TOut=any>(url: string, dataState: State, search?: string, query?: any): Promise<GridResult<TOut>> {

        const page = Math.round(dataState.skip! / dataState.take!) + 1;
        const take = dataState.take!;
        let sort = "";
        if (dataState.sort) {
            for (const sortDesc of dataState.sort) {
                if (sort) sort += ",";
                sort += `${sortDesc.field} ${sortDesc.dir}`;
            }
        }
        search = search || "";

        const newUrl = `${url}${url.indexOf("?") < 0 ? "?" : "&"}Page=${page}&RowsPerPage=${take}&SearchPhrase=${encodeURIComponent(search)}&SortOrder=${encodeURIComponent(sort)}`;
        return this.$get(newUrl, query);
    }

    public $postChunkedFile(key: string, dataIn: Blob, progressCallback: (count:number, total:number) => void): Promise<ChunkedUploadDto> {
        if (this.detailLog) console.log(`$postChunkedFile: Start - key=${key}`);
        const promise = new Promise<ChunkedUploadDto>((resolve, reject): void => {
            this.postChunkedFileStart(key, dataIn, progressCallback)
                .then((uploadDto: ChunkedUploadDto): void => {
                    uploadDto.BlockList = []; // initialize completed block list
                    uploadDto.PendingList = []; // initialize pending block list
                    progressCallback(1, 1 + uploadDto.TotalChunks + 1);
                    if (this.detailLog) console.log(`$postChunkedFile: Upload Started - key=${key} ${JSON.stringify(uploadDto)}`);
                    this.uploadChunks(uploadDto, dataIn, progressCallback)
                        .then((uploadDto: ChunkedUploadDto): void => {
                            this.finalizeFile(uploadDto, progressCallback)
                                .then((uploadDto: ChunkedUploadDto): void => {
                                    progressCallback(1 + uploadDto.TotalChunks + 1, 1 + uploadDto.TotalChunks + 1);
                                    if (this.detailLog) console.log(`$postChunkedFile: Completed - key=${key} ${JSON.stringify(uploadDto)}`);
                                    resolve(uploadDto);
                                })
                                .catch((reason: any): void => {
                                    if (this.detailLog) console.log(`$postChunkedFile: Failed - key=${key} ${reason}`);
                                    reject(reason);
                                });
                        })
                        .catch((reason: any): void => {
                            reject(reason);
                        });
                })
                .catch((reason: any): void => {
                    reject(new Error(`Upload start failed - ${reason}`));
                });
        });
        return promise;
    }

    private postChunkedFileStart(key: string, dataIn: Blob, progressCallback: (count:number, total:number) => void) : Promise<ChunkedUploadDto> {

        progressCallback(0, 999999);

        const promise = new Promise<ChunkedUploadDto>((resolve, reject): void => {
            dataIn.text()
                .then((blobData: string): void => {
                    const fileName = md5(blobData).toUpperCase();
                    this.postChunkedFileStartInternal(key, fileName, dataIn, progressCallback)
                        .then((uploadDto: ChunkedUploadDto): void => {
                            resolve(uploadDto);
                        })
                        .catch((reason: any): void => {
                            reject(reason);
                        });
                })
                .catch((reason:any): void => {
                    reject(new Error("Unable to calculate hash for file to upload."));
                });
        });
        return promise;
    }

    private postChunkedFileStartInternal(key: string, fileName: string, dataIn: Blob, progressCallback: (count:number, total:number) => void) : Promise<ChunkedUploadDto> {
        const dr = new DataRequest();
        dr.maxRetries = 5;// 5 retries for upload start
        dr.toastOnFailure = false;// don't show failure toast
        return dr.$post<ChunkedUploadDto>(`/Service/ChunkedUpload/Open/${key}`,
            { FileName: fileName, FileSize: dataIn.size, ContentType: dataIn.type });
    }

    private uploadChunks(uploadDto: ChunkedUploadDto, dataIn: Blob, progressCallback: (count:number, total:number) => void): Promise<ChunkedUploadDto> {
        const promise = new Promise<ChunkedUploadDto>((resolve, reject): void => {
            this.uploadChunksInternal(uploadDto, dataIn, progressCallback, resolve, reject);
        });
        return promise;
    }

    private uploadChunksInternal(uploadDto: ChunkedUploadDto, dataIn: Blob, progressCallback: (count:number, total:number) => void, resolvePromise: (value: ChunkedUploadDto) => void, rejectPromise:(reason?: any) => void): void {

        if (this.detailLog) console.log(`uploadChunksInternal: Start - ${JSON.stringify(uploadDto)}`);
        const block: number = this.findNextBlock(uploadDto);
        if (block < 0) {
            if (uploadDto.PendingList.length === 0) {
                if (this.detailLog) console.log(`uploadChunksInternal: Completed - ${JSON.stringify(uploadDto)}`);
                resolvePromise(uploadDto);// all blocks processed
            }
            return;
        }

        // There is at least one block
        uploadDto.PendingList.push(block);
        // trigger another (for concurrent blocks)
        setTimeout((): void => this.uploadChunksInternal(uploadDto, dataIn, progressCallback, resolvePromise, rejectPromise));
        // upload the one block
        this.uploadOneChunk(block, uploadDto, dataIn, progressCallback)
            .then((uploadDto: ChunkedUploadDto) : void => {
                setTimeout((): void => this.uploadChunksInternal(uploadDto, dataIn, progressCallback, resolvePromise, rejectPromise));
            })
            .catch((reason: any): void => {
                rejectPromise(new Error(`Upload for block ${block} failed - ${reason}`));
            })
            .finally(():void => {
                // this block is no longer pending
                uploadDto.PendingList = uploadDto.PendingList.filter((v: number, index: number): boolean => { return v !== block; });
            });
    }

    private findNextBlock(uploadDto: ChunkedUploadDto): number {
        if (uploadDto.PendingList.length >= uploadDto.Concurrent)
            return -1;// reached maximum concurrent blocks
        // BlockList is a sparse array, find an empty/missing entry
        for (let i = 0 ; i < uploadDto.TotalChunks ; ++i) {
            if (!uploadDto.BlockList[i] && uploadDto.PendingList.indexOf(i) < 0)
                return i;
        }
        return -1;
    }

    private uploadOneChunk(block: number, uploadDto: ChunkedUploadDto, dataIn: Blob, progressCallback: (count:number, total:number) => void): Promise<ChunkedUploadDto> {

        if (this.detailLog) console.log(`uploadOneChunk: Start block=${block} - ${JSON.stringify(uploadDto)}`);
        const promise = new Promise<ChunkedUploadDto>((resolve, reject): void => {

            const chunkBlob: Blob = dataIn.slice(block*uploadDto.ChunkSize, (block+1)*uploadDto.ChunkSize);
            const chunkSize = chunkBlob.size;

            const dr = new DataRequest();
            dr.maxRetries = 5;// 5 retries for chunk upload
            dr.toastOnFailure = false;// don't show failure toast
            dr.$postBlob<ChunkedUploadResponseDto>(`/Service/ChunkedUpload/Chunk/${uploadDto.Key}`,
                { FileName: uploadDto.FileName, Chunk: block, ChunkSize: chunkSize }, chunkBlob)
                .then((blockInfo: ChunkedUploadResponseDto) : void => {
                    uploadDto.BlockList[block] = blockInfo.BlockId;// save block ID
                    progressCallback(1 + this.currentChunks(uploadDto) + 1, 1 + uploadDto.TotalChunks + 1);
                    if (this.detailLog) console.log(`uploadOneChunk: Success block=${block} - ${JSON.stringify(uploadDto)}`);
                    resolve(uploadDto);
                })
                .catch((reason: any): void => {
                    if (this.detailLog) console.log(`uploadOneChunk: Fail block=${block} - ${JSON.stringify(uploadDto)}`);
                    reject(new Error(`Upload for block ${block} failed - ${reason}`));
                });
        });
        return promise;
    }

    private currentChunks(uploadDto: ChunkedUploadDto): number {
        // BlockList is a sparse array, count completed blocks
        let count = 0;
        for (let i = 0 ; i < uploadDto.TotalChunks ; ++i) {
            if (uploadDto.BlockList[i])
                ++count;
        }
        return count;
    }

    private finalizeFile(uploadDto: ChunkedUploadDto, progressCallback: (count:number, total:number) => void): Promise<ChunkedUploadDto> {
        if (this.detailLog) console.log(`finalizeFile: Start - ${JSON.stringify(uploadDto)}`);
        const promise = new Promise<ChunkedUploadDto>((resolve, reject): void => {
            const dr = new DataRequest();
            dr.maxRetries = 5;// 5 retries for chunk close
            dr.toastOnFailure = false;// don't show failure toast
            dr.$post<any, any>(`/Service/ChunkedUpload/Close/${uploadDto.Key}`,
                null, uploadDto)
                .then(() : void => {
                    if (this.detailLog) console.log(`finalizeFile: End Success - ${JSON.stringify(uploadDto)}`);
                    resolve(uploadDto);
                })
                .catch((reason: any): void => {
                    if (this.detailLog) console.log(`finalizeFile: End Fail - ${reason}`);
                    reject(new Error(`Upload for image failed - ${reason}`));
                });
        });
        return promise;
    }
}

