

import HelpModal from "@/views/HelpModal.vue";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options<Help>({
    components: {
        HelpModal,
    }
})
export default class Help extends Vue {

    @Prop({ default: "left" }) readonly horizontal: string = "left";
    @Prop({ default: false }) readonly loading: boolean = false;

    helpClicked(): void {
        if (this.loading) return;
        this.$refs.HelpModalRef.show();
    }

    declare $refs: {
        HelpModalRef: HelpModal,
    }
}

