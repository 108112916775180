import Toast from "@/shared/support/Toast";

export default class Debug {

    public static initialize(): void {

        if (process.env.VUE_APP_DEBUG !== "1") return;

        console.debug(`process.env: ${JSON.stringify(process.env)}`);

        let inDebug = false;
        window.onerror = (ev: Event | string, url?: string, lineNo?: number, columnNo?: number, error?: Error): void => {
            if (!inDebug) {
                inDebug = true;
        
                const evMsg = ev.toString();
                // avoid recursive error with video controls. a bit hacky but this is just a debugging tool.
                if (evMsg.startsWith("ResizeObserver")) return;
        
                const msg = `${evMsg} (${url}:${lineNo}) ${error?.stack}`;
                Toast.error(msg);
                console.error(msg);
        
                inDebug = false;
            }
        };
    }

    public static verifyIds(): void { 
        // Verify that we don't have duplicate element ids, which would be an error (usually an incorrectly generated component). Id collisions due to SPA should not happen. This will pinpoint any such errors.

        if (process.env.VUE_APP_DEBUG !== "1") return;

        const elems = document.querySelectorAll("[id]");
        const arr: HTMLElement[] = [];

        for (const elem of elems) {
            const id = elem.id;
            const found = arr.find((el: HTMLElement): boolean => { return el.id === id; });
            if (!found) {
                arr.push(elem as HTMLElement);
            } else {
                const msg = `Duplicate id ${id} in element ${elem.outerHTML} - like ${found.outerHTML}`;
                Toast.error(msg);
                console.error(msg);
            }
        }
    }
}
