/**
 * A global class that supports many commonly used functions throughout an application.
 */

import { DataRequest } from "./Data";

export interface ImageSize {
    Width: number;
    Height: number;
}

export default class Utility {

    // Encoding/decoding

    /**
     * Encodes a string that contains HTML tags.
     * @param html string to be encoded.
     * @returns An encoded string where HTML tags are replaced with displayable characters.
     */
    public static encodeHTML(html: string): string {
        let textArea = Utility.cachedTextArea;
        if (!textArea)
            textArea = Utility.cachedTextArea = document.createElement("textarea");
        textArea.innerHTML = html;
        const text = textArea.innerHTML;
        return text;
    }
    private static cachedTextArea: HTMLTextAreaElement|null = null;

    // Date/Time formatting

    public static getFormattedDateTimeHHMMSS(date: Date | null): string {
        if (!date) return "";
        const time = date.getHours()* 60 + date.getMinutes();
        return this.getFormattedDate(date) + " " + this.getFormattedTimeSeconds(time, date.getSeconds());
    }
    public static getFormattedDateTime(date: Date | null): string {
        if (!date) return "";
        const time = date.getHours()* 60 + date.getMinutes();
        return this.getFormattedDate(date) + " " + this.getFormattedTime(time);
    }
    public static getFormattedDate(date: Date | null): string {
        if (!date) return "";
        const d = date.getDate();
        const m = date.getMonth() + 1;
        const y = date.getFullYear();
        return `${this.zeroLeftPad(m, 2)}/${this.zeroLeftPad(d, 2)}/${y}`;
    }
    public static getFormattedTime(time: number):string {
        const h = Math.floor(time/60);
        const m =  time % 60;
        let hour = h % 12;
        if (hour === 0) hour += 12;
        return `${this.zeroLeftPad(hour, 2)}:${this.zeroLeftPad(m, 2)} ${h < 12 ? "AM" : "PM"}`;
    }
    public static getFormattedTimeSeconds(time: number, seconds: number):string {
        const h = Math.floor(time/60);
        const m =  time % 60;
        let hour = h % 12;
        if (hour === 0) hour += 12;
        return `${this.zeroLeftPad(hour, 2)}:${this.zeroLeftPad(m, 2)}:${this.zeroLeftPad(seconds, 2)} ${h < 12 ? "AM" : "PM"}`;
    }
    public static zeroLeftPad(val: number, pos: number): string {
        if (val < 0) return val.toFixed();
        let s = val.toFixed(0);
        while (s.length < pos)
            s = "0" + s;
        return s;
    }

    // DateOnly

    /**
     * Format a C# DateOnly. Unfortunately we're not using .net6 everywhere so this arrives as a DateTimeOffset with timezone +00:00
     * @param date string (UTC+0) to be formatted as a displayable date.
     * @returns A formatted date string.
     */
    public static getFormattedDateOnly(date: string | null): string {
        if (!date) return "";
        if (!date.endsWith("T00:00:00+00:00")) return "(invalid)";
        const d = date.substring(8,8+2);
        const m = date.substring(5,5+2);
        const y = date.substring(0,0+4);
        return `${m}/${d}/${y}`;
    }

    // Currency

    public static formatCurrencyDisplay(value: number): string {
        const formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2
        });
        return formatter.format(value);
    }

    public static parseCurrency(n: number): number {
        const multiplicator = Math.pow(10, 2);
        n = parseFloat((n * multiplicator).toFixed(11));
        const result = (Math.round(n) / multiplicator);
        return +(result.toFixed(2));
    }

    // KB, MB, GB

    public static formatSizeInKB(size: number): string {
        if (size < 1024) return `${size.toString()} bytes`;
        if (size < 1024*1024) return `${(size / 1024).toFixed(1)} KB`;
        if (size < 1024*1024*1024) return `${(size / (1024*1024)).toFixed(1)} MB`;
        return `${(size / (1024*1024*1024)).toFixed(1)} GB`;
    }

    // URL

    /**
     *
     * @param url Format a complete URL. E.g., formatUrl("/sample/page", { id: 123, search: "abc:"});
     * @param query Query string arguments.
     * @param domain Optional domain (https://......./). If not supplied, the current domain is used.
     * @param auth Optionally adds bearer token as query string argument.
     * @returns A formatted URL.
     */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public static formatUrl(url: string, query: any, domain?: string, auth?: boolean): string {
        if (!domain)
            domain = `${window.origin}/`;
        if (url.startsWith("/")) url = url.substring(1);
        let newUrl = `${domain}${url}`;
        let first = true;
        if (query) {
            for (const propertyName in query) {
                if (first)
                    newUrl += "?";
                else
                    newUrl += "&";
                first = false;
                newUrl += `${encodeURIComponent(propertyName)}=`;
                const val = query[propertyName];
                if (val !== undefined && val != null)
                    newUrl += `${encodeURIComponent(val)}`;
            }
        }
        if (auth) {
            // add bearer token
            if (DataRequest.getToken && DataRequest.haveToken) {
                const token = DataRequest.getToken();
                if (first)
                    newUrl += "?";
                else
                    newUrl += "&";
                first = false;
                newUrl += "__BearerToken=";
                if (token !== undefined && token != null)
                    newUrl += token;
            }
        }
        return newUrl;
    }

    /**
     *
     * @param url Format a complete path (without schema and domain). E.g., formatUrl("/sample/page", { id: 123, search: "abc:"});
     * @param query Query string arguments.
     * @returns A formatted URL.
     */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public static formatPath(url: string, query: any): string {
        let newUrl = url;
        let first = true;
        if (query) {
            for (const propertyName in query) {
                if (first)
                    newUrl += "?";
                else
                    newUrl += "&";
                first = false;
                newUrl += `${encodeURIComponent(propertyName)}=`;
                const val = query[propertyName];
                if (val !== undefined && val != null)
                    newUrl += `${encodeURIComponent(val)}`;
            }
        }
        return newUrl;
    }

    // Image Size

    public static getImageDimensions(file: File): Promise<ImageSize> {

        let resolvePromise: (value: ImageSize) => void;
        let rejectPromise: (reason?: any) => void;
        const promise = new Promise<ImageSize>((resolve, reject):void => {
            resolvePromise = resolve;
            rejectPromise = reject;
        });

        const img = document.createElement("img") as HTMLImageElement;
        const blob = URL.createObjectURL(file);
        img.onload = (): void => {
            const w = img.width;
            const h = img.height;
            img.remove();
            resolvePromise({ Width: w, Height: h });
        };
        img.onerror = (): void => {
            img.remove();
            rejectPromise();
        };
        img.src = blob;

        return promise;
    }

    public static formatSerialNumber(serial: string): string {
        const arr = serial.match(/.{1,4}/g);
        if (arr)
            serial = arr.join("-");
        return serial;
    }

    // Page Reload/Change

    // Page modification support (used with onbeforeunload)
    public static get pageChanged(): boolean {
        return this.pageChangedCache;
    }
    public static set pageChanged(value: boolean) {
        if (this.pageChangedCache !== value) {
            this.pageChangedCache = value;
        }
    }
    private static pageChangedCache: boolean = false;
}
