

import { Options } from "vue-class-component";

import PageBase from "@/shared/components/common/PageBase";
import HeaderMessage from "@/components/HeaderMessage.vue";
import { Global } from "@/support/GlobalData";
import { SignalR } from "@/support/SignalR";
import { CalibrationEventSupport } from "@/support/CalibrationEvent";
import { CalibrationEvent } from "@/shared/enums/CalibrationEvent";
import { DeviceStatusDto } from "@/shared/models/DeviceStatusDto";
import { confirmYesNo } from "@/shared/components/common/AlertDialog.vue";
import { ButtonClicked } from "@/shared/enums/ButtonClicked";

@Options({
    components: {
        HeaderMessage,
    }
})
export default class CalibrationNewWindshieldAngle extends PageBase {

    angle: number = 0;

    get angleText(): string {
        if (!this.angle) return "";
        return this.angle.toFixed(1);
    }
    set angleText(v: string) {
        try {
            this.angle = Number(v);
        } catch(e: any) {
            this.angle = 0;
        }
    }

    get isValid(): boolean {
        return this.angleText.match(/^[1-9][0-9]{0,1}(\.[0-9]){0,1}$/) != null;
    }
    accept(): void {
        if (!this.isValid) return;
        this.nextClicked();
    }

    nextClicked(): void {
        if (!Global.OriginalWindshieldAngle || this.angle < Global.OriginalWindshieldAngle - 2.0 || this.angle > Global.OriginalWindshieldAngle + 2.0) {
            Global.CalibrationSession!.WindshieldOverride = null; // clear override
            this.$router.push("/Calibration/ContactSupportWindshieldAngle");
        } else {
            this.loading = true;
            Global.CalibrationSession!.WindshieldOverride = this.angle;
            CalibrationEventSupport.sendEventCalibrationRestart(CalibrationEvent.RestartCalibration, this.angle);
        }
    }

    cancelClicked(): void {
        confirmYesNo("Are you sure you want to cancel this calibration?")
            .then((clicked: ButtonClicked): void => {
                if (clicked === ButtonClicked.Ok) {
                    CalibrationEventSupport.sendEvent(CalibrationEvent.CalibrationCustomerCanceled);
                    Global.MainApp.clearSession();
                    this.$router.push("/");
                }
            });
    }

    setWindshieldAngleByCompanion(angle: number): void {
        this.angle = angle;
        this.nextClicked();
    }

    getCurrentStatusForListeners(): void {
        CalibrationEventSupport.sendEventStatus({
            Page: "CalibrationNewWindshieldAngle",
        } as DeviceStatusDto);
    }

    mounted(): void {
        SignalR.onGetCurrentStatusForListeners = this.getCurrentStatusForListeners;
        SignalR.onWindshieldAngleSelectionByCompanion = this.setWindshieldAngleByCompanion;
        this.getCurrentStatusForListeners();
    }

    unmounted(): void {
        SignalR.onGetCurrentStatusForListeners = null;
        SignalR.onWindshieldAngleSelectionByCompanion = null;
    }
}
