

import { Vue, Options } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
})
export default class FormLabel extends Vue {

    @Prop({ default: false }) readonly required: boolean = false;
    @Prop({ default: "" }) readonly tooltip: string = "";
    @Prop({ default: "" }) readonly forId: string = "";

}

