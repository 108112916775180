

import { Options } from "vue-class-component";

import PageBase from "@/shared/components/common/PageBase";
import HeaderMessage from "@/components/HeaderMessage.vue";
import { Global } from "@/support/GlobalData";
import { CalibrationEventSupport } from "@/support/CalibrationEvent";
import { DeviceStatusDto } from "@/shared/models/DeviceStatusDto";
import { SignalR } from "@/support/SignalR";

@Options({
    components: {
        HeaderMessage,
    }
})
export default class CalibrationFailed extends PageBase {

    acknowledgeClicked(): void {
        CalibrationEventSupport.sendEventStatus({
            Page: "CalibrationEnded",
        } as DeviceStatusDto);

        Global.MainApp.clearSession();
        this.$router.push("/");
    }

    getCurrentStatusForListeners(): void {
        CalibrationEventSupport.sendEventStatus({
            Page: "CalibrationFailed",
        } as DeviceStatusDto);
    }

    mounted(): void {
        SignalR.onGetCurrentStatusForListeners = this.getCurrentStatusForListeners;
        SignalR.onAcknowledgeByCompanion = this.acknowledgeClicked;
        SignalR.onCalibrationFailed = (): void => {
            // no action (ignore)
        };
        this.getCurrentStatusForListeners();
    }
    unmounted(): void {
        SignalR.onGetCurrentStatusForListeners = null;
        SignalR.onAcknowledgeByCompanion = null;
        SignalR.onCalibrationFailed = null;
    }
}
