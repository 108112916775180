
export enum CalibrationType {
    All = 0,
    OneTime = 1,
    Sequential = 2,
}

export enum CalibrationTypeSpecific {
    OneTime = 1,
    Sequential = 2,
}

export const CalibrationTypeDescription: string[] = [
    "All",
    "One Time",
    "Sequential",
];

