import { Tooltip } from "bootstrap";

/**
 * A global class that supports tooltips throughout an application.
 */
export default class TooltipSupport {

    /**
     * Used to initialize tooltip support for the entire application.
     */
    public static initializeTooltips(): void {
        TooltipSupport.bsTooltip = new Tooltip(document.body, {
            selector: "[data-bs-toggle='tooltip']",
            placement: "top",
            container: "body",
            trigger: "hover",
        });
    }
    private static bsTooltip: bootstrap.Tooltip = null!;

    /**
     * Clears all tooltips. Use when a tooltip gets "stuck" which is a common problem
     * when button event handlers remove the item that has a tooltip or when a new window is opened.
     */
    public static clearTooltips(): void {
        this.fastClearTooltips();// clear initially
        setTimeout((): void => {
            this.fastClearTooltips();// clear it again after a couple of seconds (used when a new window is opened (e.g. for download))
        }, 2000);
    }
    private static fastClearTooltips(): void {
        const elems = document.querySelectorAll("[data-bs-toggle='tooltip']");
        for (const elem of elems) {
            const tooltip = Tooltip.getInstance(elem);
            if (tooltip)
                tooltip.hide();
        }
    }
}
