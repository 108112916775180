

import { Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

export default class Loading extends Vue {

    @Prop({ default: false }) readonly loading: boolean = false;
    @Prop({ default: "Loading..." }) readonly text: string = "Loading...";

}

