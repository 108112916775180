
export enum MarkerType {
    Standard = 0,
    HighAngle = 1,
}

export const MarkerTypeDescription: string[] = [
    "Standard Marker",
    "High-Angle Marker",
];
