

import SimpleKeyboard from "./SimpleKeyboard.vue";
import { defineComponent } from "vue";

export default defineComponent({
    components: {
        SimpleKeyboard,
    },
    props: {
        modelValue: {type: String, default: "" },
        placeholder: { type: String, required: false, default: ""},
        maxlength: { type: Number, required: true, default: 99 },
        cssClass: { type: String, required: false, default: "" },
        readonly: { type: Boolean, required: false, default: false },
        autoFocus: { type: Boolean, required: false, default: false },
        allowPeriod: { type: Boolean, required: false, default: true },
        inputName: { type: String, required: false, default: "" },
    },
    data() {
        return {
            text: (this.modelValue||"") as string,
        };
    },
    methods: {
        showKeyboard(): void {
            if (this.readonly) return;
            setTimeout((): void => {
                (this.$refs.simplekeyboardref as typeof SimpleKeyboard).showKeyboard();
            }, 500); // delay so DOM is ready ($nextTick isn't enough)
        },
        onChange(input: string): void {
            if (this.readonly) return;
            if (input.length > this.maxlength) return;
            this.text = input;
        },
        inputClick(): void {
            (this.$refs.inputref as HTMLInputElement).focus();
            this.showKeyboard();
        },
        accept(): void {
            this.$emit("accept");
        }
    },
    mounted(): void {
        if (this.autoFocus) {
            setTimeout((): void => {
                (this.$refs.inputref as HTMLInputElement).focus();
            }, 500); // delay so DOM is ready ($nextTick isn't enough)
        }
    },
    watch: {
        text(): void {
            this.$emit("update:modelValue", this.text);
        }
    }
});
