
export enum RegionType {
    Domestic = 0,
    European = 1,
}

export const RegionTypeDescription: string[] = [
    "North America",
    "Europe",
    "All"
];

export enum RegionTypeAll {
    All = -1,
    Domestic = 0,
    European = 1,
}

export const RegionTypeDescriptionAll = "All";