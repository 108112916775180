
export enum SessionType {
    Unknown=0,
    AssistedCalibration,
    SelfCalibration,
    AssistedTargetDisplay,
}

export const SessionTypeDescription: string[] = [
    "",
    "Assisted",
    "Self",
    "Target Request",
];