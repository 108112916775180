

import { Options } from "vue-class-component";

import PageBase from "@/shared/components/common/PageBase";
import HeaderMessage from "@/components/HeaderMessage.vue";
import { SignalR } from "@/support/SignalR";
import { CalibrationEventSupport } from "@/support/CalibrationEvent";
import Utility from "@/shared/support/Utility";
import { Global } from "@/support/GlobalData";
import { DataRequest } from "@/shared/support/Data";
import { ButtonClicked } from "@/shared/enums/ButtonClicked";
import { confirmOk, confirmYesNo } from "@/shared/components/common/AlertDialog.vue";
import { CameraImageDto } from "@/shared/models/CameraImageDto";
import { DeviceStatusDto } from "@/shared/models/DeviceStatusDto";
import { CalibrationEvent } from "@/shared/enums/CalibrationEvent";

@Options({
    components: {
        HeaderMessage,
    }
})
export default class CalibrationCameraImage extends PageBase {

    get cameraImage(): string {
        return this.cameraImageCache;
    }
    set cameraImage(image: string) {
        this.cameraImageCache = image;
    }
    cameraImageCache = "";

    vehicle = `${Global.Year} ${Global.Make} ${Global.Model}`;
    vehicleMake = Global.Make;
    vehicleModel = Global.Model;
    vehicleYear = Global.Year;

    loadCameraImage(): void {

        this.loading = true;

        if (Global.IsDemo()) {

            this.cameraImage = Global.DemoCameraImage;
            this.loading = false;

        } else {

            const request = new DataRequest();
            request.$post<any, CameraImageDto>("/Service/CameraParser/GetCameraImageInfo", { CalibrationSessionGuid: Global.CalibrationSession!.CalibrationSessionGuid })
                .then((cameraImage: CameraImageDto): void => {
                    if (!cameraImage.Url) {
                        this.loading = false;
                        confirmOk("An unexpected error occurred retrieving the camera image");
                        this.$router.push("/Calibration/CameraSetup");
                        return;
                    }
                    const url = Utility.formatUrl(cameraImage.Url, {}, Global.GetWebAPIUrl(), true);
                    this.loading = true; // we'll get an onload event (cameraImageLoaded) which clears the loading indicator
                    this.cameraImage = url;
                })
                .catch((reason): void => {
                    this.loading = false;
                    const msg = `An unexpected error occurred retrieving the camera image - ${reason}`;
                    CalibrationEventSupport.sendEventDeviceFailure(msg);
                    confirmOk(msg);
                    this.$router.push("/Calibration/CameraSetup");
                });

        }
    }

    cameraImageLoaded(): void {
        this.loading = false;

        CalibrationEventSupport.sendEventStatus({
            Page: "CalibrationCameraImage",
        } as DeviceStatusDto);
    }

    cancelClicked(): void {
        confirmYesNo("Are you sure you want to cancel this calibration?")
            .then((clicked: ButtonClicked): void => {
                if (clicked === ButtonClicked.Ok) {
                    CalibrationEventSupport.sendEvent(CalibrationEvent.CalibrationCustomerCanceled);
                    Global.MainApp.clearSession();
                    this.$router.push("/");
                }
            });
    }

    recalibrateClicked(): void {
        this.$router.push("/Calibration/CameraSetup");
    }

    sendFirstImageClicked(): void {
        this.$router.push("/Calibration/SendTargets");
    }

    getCurrentStatusForListeners(): void {
        if (this.loading) {
            CalibrationEventSupport.sendEventStatus({
                Page: "CalibrationCameraReady",
                CameraVerifying: true,
            } as DeviceStatusDto);
        } else {
            CalibrationEventSupport.sendEventStatus({
                Page: "CalibrationCameraImage",
            } as DeviceStatusDto);
        }
    }

    mounted(): void {
        SignalR.onGetCurrentStatusForListeners = this.getCurrentStatusForListeners;
        SignalR.onSendTargetByCompanion = this.sendFirstImageClicked;
        SignalR.onRestartCalibration = this.recalibrateClicked;
        this.loadCameraImage();
    }
    unmounted(): void {
        SignalR.onGetCurrentStatusForListeners = null;
        SignalR.onSendTargetByCompanion = null;
        SignalR.onRestartCalibration = null;
    }
}
