

import { Options } from "vue-class-component";

import PageBase from "@/shared/components/common/PageBase";
import HeaderMessage from "@/components/HeaderMessage.vue";

import { ButtonClicked } from "@/shared/enums/ButtonClicked";
import { confirmOk, confirmYesNo } from "@/shared/components/common/AlertDialog.vue";
import { CalibrationEventSupport } from "@/support/CalibrationEvent";
import { CalibrationEvent } from "@/shared/enums/CalibrationEvent";
import { Global } from "@/support/GlobalData";
import { SignalR } from "@/support/SignalR";
import { DeviceStatusDto } from "@/shared/models/DeviceStatusDto";
import { MarkerType } from "@/shared/enums/MarkerType";

@Options({
    components: {
        HeaderMessage,
    }
})
export default class CalibrationCameraSetup extends PageBase {

    legA(): number {
        return Global.LegLengthA;
    }
    legB(): number {
        return Global.LegLengthB;
    }
    legC(): number {
        return Global.LegLengthC;
    }
    get standardMarker(): boolean { return Global.Marker === MarkerType.Standard; }
    get highAngleMarker(): boolean { return Global.Marker === MarkerType.HighAngle; }

    get IsControlled(): boolean {
        return Global.IsTechControlled;
    }

    cancelClicked(): void {
        confirmYesNo("Are you sure you want to cancel this calibration?")
            .then((clicked: ButtonClicked): void => {
                if (clicked === ButtonClicked.Ok) {
                    CalibrationEventSupport.sendEvent(CalibrationEvent.CalibrationCustomerCanceled);
                    Global.MainApp.clearSession();
                    this.$router.push("/");
                }
            });
    }
    nextClicked(): void {
        if (!this.legA() && !this.legB() && !this.legC()) {
            // Hacky fix to avoid mayhem when leg lengths are lost. We don't know why it happens but apparently it does.
            // Repro case is to hit F5 on this page, which isn't possible on an Auggie (only with kbd or on a VM).
            // See APD-8548
            confirmOk("The calibration session information has been lost and the session has restarted.");
            Global.MainApp.clearSession();
            this.$router.push("/");
            return;
        }
        SignalR.onGetCameraAlignmentImage = null;
        this.$router.push("/Calibration/CameraReady");
    }

    getCurrentStatusForListeners(): void {
        CalibrationEventSupport.sendEventStatus({
            Page: "CalibrationCameraSetup",
        } as DeviceStatusDto);
    }

    mounted() : void {
        SignalR.onGetCurrentStatusForListeners = this.getCurrentStatusForListeners;
        if (Global.IsTechControlled) {
            SignalR.onGetCameraAlignmentImage = (): void => { /*ignore*/ };
            setTimeout((): void => {
                this.nextClicked();
            }, 5000);
        } else {
            SignalR.onGetCameraAlignmentImage = this.nextClicked;
        }
        this.getCurrentStatusForListeners();
    }
    unmounted(): void {
        SignalR.onGetCurrentStatusForListeners = null;
        SignalR.onGetCameraAlignmentImage = null;
    }
}
