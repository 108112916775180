import { Vue } from "vue-class-component";
import { Modal } from "bootstrap";
import Debug from "@/shared/support/Debug";
import TooltipSupport from "@/shared/support/Tooltip";

export default class ModalBase extends Vue {

    static activeModal: ModalBase|null = null;

    modal: Modal|null = null;
    loading: boolean = false;
    inited: boolean = false;

    /**
     * Shows a modal.
     */
    protected showModal(): void {
        TooltipSupport.clearTooltips(); // forces any lingering tooltips to be removed
        ModalBase.killModals();// bootstrap modals only support 1 modal at a time
        this.modal = new Modal(this.$el as Element, {
            backdrop: "static", // A backdrop which doesn't close the modal on click.
            keyboard: false, // Doesn't close the modal when escape key is pressed
            focus: true, // Puts the focus on the modal when initialized.
        });
        this.modal.show();
        Debug.verifyIds();
        ModalBase.activeModal = this;
    }
    /**
     * Hides the modal. Optionally sends a modal-update event to the owner of the modal. This could be used to reload a grid.
     * @param forceReload Sends an unload event to the owner of the modal. Default is false.
     */
    public hideModal(forceReload?: boolean): boolean {
        ModalBase.activeModal = null;
        if (this.modal) {
            this.modal.hide();
            this.modal = null;
            if (forceReload === true)
                this.$emit("modal-update");
            return true;
        }
        return false;
    }

    initializing(): boolean {
        return !this.inited;
    }
    initialized(): void {
        this.inited = true;
    }

    /**
     * Default Cancel button handler.
     * Doesn't send a modal-update event.
     */
    public cancelClicked():void {
        this.hideModal();
    }

    public static killModals(): void {
        if (ModalBase.activeModal)
            ModalBase.activeModal.hideModal();
    }
}
