import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_qrcode_vue = _resolveComponent("qrcode-vue")

  return (_openBlock(), _createBlock(_component_qrcode_vue, {
    value: $data.value,
    size: $data.size,
    margin: $data.margin,
    level: "H"
  }, null, 8, ["value", "size", "margin"]))
}