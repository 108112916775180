import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  id: "confirmModal",
  class: "modal fade",
  tabindex: "-1",
  "aria-labelledby": "confirmModalLabel",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-content" }
const _hoisted_3 = {
  key: 0,
  class: "modal-header"
}
const _hoisted_4 = {
  id: "confirmModalLabel",
  class: "modal-title"
}
const _hoisted_5 = { class: "modal-body" }
const _hoisted_6 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_button_button = _resolveComponent("button-button")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", {
      class: `modal-dialog modal-dialog-scrollable modal-dialog-centered ${_ctx.largeModal ? 'modal-lg' : ''}`
    }, [
      _createVNode("div", _hoisted_2, [
        (_ctx.title)
          ? (_openBlock(), _createBlock("div", _hoisted_3, [
              _createVNode("h5", _hoisted_4, _toDisplayString(_ctx.title), 1),
              _createVNode(_component_button_button, {
                type: "button",
                class: "btn-close",
                "aria-label": "Close",
                onClick: _ctx.cancelClicked
              }, null, 8, ["onClick"])
            ]))
          : _createCommentVNode("", true),
        _createVNode("div", _hoisted_5, _toDisplayString(_ctx.message), 1),
        _createVNode("div", _hoisted_6, [
          (_ctx.cancelButton)
            ? (_openBlock(), _createBlock(_component_button_button, {
                key: 0,
                type: "button",
                class: "btn btn-secondary",
                onClick: _ctx.cancelClicked
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.cancelButton), 1)
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true),
          (_ctx.alt1Button)
            ? (_openBlock(), _createBlock(_component_button_button, {
                key: 1,
                type: "button",
                class: "btn btn-secondary",
                onClick: _ctx.alt1Clicked
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.alt1Button), 1)
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true),
          (_ctx.okButton)
            ? (_openBlock(), _createBlock(_component_button_button, {
                key: 2,
                type: "button",
                class: "btn btn-primary",
                onClick: _ctx.okClicked
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.okButton), 1)
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ])
      ])
    ], 2)
  ]))
}