

import { Options } from "vue-class-component";

import PageBase from "@/shared/components/common/PageBase";
import HeaderMessage from "@/components/HeaderMessage.vue";
import { ButtonClicked } from "@/shared/enums/ButtonClicked";
import { confirmYesNo, showAlertDialog } from "@/shared/components/common/AlertDialog.vue";
import { Global } from "@/support/GlobalData";
import { SignalR } from "@/support/SignalR";
import { CalibrationEventSupport } from "@/support/CalibrationEvent";
import { CalibrationEvent } from "@/shared/enums/CalibrationEvent";
import { DeviceStatusDto } from "@/shared/models/DeviceStatusDto";

@Options({
    components: {
        HeaderMessage,
    }
})
export default class CalibrationRideHeight extends PageBase {

    frontRight = "";
    frontLeft = "";
    rearRight = "";
    rearLeft = "";

    nextClicked(): void {
        const fl = Number(this.frontLeft);
        const fr = Number(this.frontRight);
        const rl = Number(this.rearLeft);
        const rr = Number(this.rearRight);
        if (!this.isValidHeight(fl) || !this.isValidHeight(fr) || !this.isValidHeight(rl) || !this.isValidHeight(rr)) {
            showAlertDialog("Measurement(s) outside normal range for this vehicle. Verify measurements (see picture below) are accurate and entered in millimeters. Contact Auggie support for assistance.",
                undefined, { okButton: "OK", largeModal: true });
            return;
        }
        CalibrationEventSupport.sendEventRideHeight(CalibrationEvent.RideHeightEntry, fl, fr, rl, rr);
        this.$router.push("/Calibration/CameraSetup");
    }
    isValid(): boolean {
        if (!this.frontLeft.length || isNaN(Number(this.frontLeft))) return false;
        if (!this.frontRight.length || isNaN(Number(this.frontRight))) return false;
        if (!this.rearLeft.length || isNaN(Number(this.rearLeft))) return false;
        if (!this.rearRight.length || isNaN(Number(this.rearRight))) return false;
        return true;
    }
    isValidHeight(height: number): boolean {
        if (!Global.WheelArchHeightMin || !Global.WheelArchHeightMax) return true;
        return Global.WheelArchHeightMin < height && height <= Global.WheelArchHeightMax;
    }

    setRideHeightByCompanion(): void {
        this.$router.push("/Calibration/CameraSetup");
    }

    cancelClicked(): void {
        confirmYesNo("Are you sure you want to cancel this calibration?")
            .then((clicked: ButtonClicked): void => {
                if (clicked === ButtonClicked.Ok) {
                    CalibrationEventSupport.sendEvent(CalibrationEvent.CalibrationCustomerCanceled);
                    Global.MainApp.clearSession();
                    this.$router.push("/");
                }
            });
    }

    getCurrentStatusForListeners(): void {
        CalibrationEventSupport.sendEventStatus({
            Page: "CalibrationRideHeight",
        } as DeviceStatusDto);
    }

    mounted(): void {
        if (!Global.CalibrationSession!.WheelArchHeightEnabledInd || (!Global.WheelArchHeightMin && !Global.WheelArchHeightMax)) {
            this.$router.push("/Calibration/CameraSetup");
            return;
        }
        SignalR.onGetCurrentStatusForListeners = this.getCurrentStatusForListeners;
        SignalR.onRideHeightEntryByCompanion = this.setRideHeightByCompanion;
        this.getCurrentStatusForListeners();
    }

    unmounted(): void {
        SignalR.onGetCurrentStatusForListeners = null;
        SignalR.onRideHeightEntryByCompanion = null;
    }

}
