

import { Options } from "vue-class-component";

import PageBase from "@/shared/components/common/PageBase";
import HeaderMessage from "@/components/HeaderMessage.vue";
import { ButtonClicked } from "@/shared/enums/ButtonClicked";
import { confirmYesNo } from "@/shared/components/common/AlertDialog.vue";
import { CalibrationEventSupport } from "@/support/CalibrationEvent";
import { CalibrationEvent } from "@/shared/enums/CalibrationEvent";
import { SignalR } from "@/support/SignalR";
import { Global } from "@/support/GlobalData";
import { DeviceStatusDto } from "@/shared/models/DeviceStatusDto";

@Options({
    components: {
        HeaderMessage,
    }
})
export default class CalibrationContacting extends PageBase {

    cancelClicked(): void {
        confirmYesNo("Are you sure you want to cancel this calibration?")
            .then((clicked: ButtonClicked): void => {
                if (clicked === ButtonClicked.Ok) {
                    CalibrationEventSupport.sendEvent(CalibrationEvent.CalibrationCustomerCanceled);
                    Global.MainApp.clearSession();
                    this.$router.push("/");
                }
            });
    }

    getCurrentStatusForListeners(): void {
        CalibrationEventSupport.sendEventStatus({
            Page: "CalibrationContacting",
        } as DeviceStatusDto);
    }

    mounted(): void {
        SignalR.onGetCurrentStatusForListeners = this.getCurrentStatusForListeners;
        SignalR.onTechnicianConnectedSession = (techName: string): void => {
            this.$router.push("/Calibration/InProgress");
        };
        this.getCurrentStatusForListeners();
        if (Global.IsDemo()) {
            setTimeout((): void => {
                this.$router.push("/Calibration/InProgress");
            }, Number(process.env.VUE_APP_DEMODELAY!) * 1000);
        }
    }
    unmounted(): void {
        SignalR.onGetCurrentStatusForListeners = null;
        SignalR.onTechnicianConnectedSession = null;
    }
}
