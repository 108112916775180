

import { Options } from "vue-class-component";

import PageBase from "@/shared/components/common/PageBase";
import HeaderMessage from "@/components/HeaderMessage.vue";

import { CalibrationEventSupport } from "@/support/CalibrationEvent";
import { Global } from "@/support/GlobalData";
import { SignalR } from "@/support/SignalR";
import { DeviceStatusDto } from "@/shared/models/DeviceStatusDto";

@Options({
    components: {
        HeaderMessage,
    }
})
export default class CalibrationMeasuredWindshieldAngle extends PageBase {

    get angleText(): string {
        if (Global.CalibrationSession!.WindshieldOverride) return Global.CalibrationSession!.WindshieldOverride.toFixed(1);
        return Global.CameraViewerConfiguration!.CarWindshieldAngle.toFixed(1);
    }
    get angle(): number {
        return Number(this.angleText);
    }

    yesClicked(): void {
        this.$router.push("/Calibration/CameraSetup");
    }
    noClicked(): void {
        this.$router.push("/Calibration/NewWindshieldAngle");
    }

    getCurrentStatusForListeners(): void {
        CalibrationEventSupport.sendEventStatus({
            Page: "CalibrationMeasuredWindshieldAngle",
            WindshieldAngle: this.angle,
        } as DeviceStatusDto);
    }

    mounted() : void {
        SignalR.onGetCurrentStatusForListeners = this.getCurrentStatusForListeners;
        SignalR.onMeasuredWindshieldAngleCorrectByCompanion = (correct: boolean): void => {
            if (correct)
                this.yesClicked();
            else
                this.noClicked();
        };
        this.getCurrentStatusForListeners();
    }
    unmounted(): void {
        SignalR.onGetCurrentStatusForListeners = null;
        SignalR.onMeasuredWindshieldAngleCorrectByCompanion = null;
    }
}
