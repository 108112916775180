

import { Options } from "vue-class-component";

import PageBase from "@/shared/components/common/PageBase";
import HeaderMessage from "@/components/HeaderMessage.vue";
import { SignalR } from "@/support/SignalR";
import { CalibrationEventSupport } from "@/support/CalibrationEvent";
import { CalibrationEvent } from "@/shared/enums/CalibrationEvent";
import { AUGGIEAVAILABLE } from "@/main";
import Utility from "@/shared/support/Utility";
import { Global } from "@/support/GlobalData";
import { DataRequest } from "@/shared/support/Data";
import { ButtonClicked } from "@/shared/enums/ButtonClicked";
import { confirmOk, confirmYesNo } from "@/shared/components/common/AlertDialog.vue";
import { VehicleDto } from "@/shared/models/VehicleDto";
import { VehicleCalibrationDto } from "@/shared/models/VehicleCalibrationDto";
import TargetRendererDto from "@/shared/models/TargetRendererDto";
import { DeviceStatusDto } from "@/shared/models/DeviceStatusDto";
import { CalibrationType } from "@/shared/enums/CalibrationType";

@Options({
    components: {
        HeaderMessage,
    }
})
export default class CalibrationSendTargets extends PageBase {

    imageSource = "";
    imageNumber: number = 0;
    totalImages: number = 0;

    targetImageUploadId: number|null = null;

    vehicleDescription = `${Global.Year} ${Global.Make} ${Global.Model}`;
    vehicleMake = Global.Make;
    vehicleModel = Global.Model;
    vehicleYear = Global.Year;
    vehicle: VehicleDto | null = null;

    connecting = false;
    sendingImage = false;
    receivingImageConfirmation = false;

    sendFirstImageClicked(): void {
        this.getDisplayImage(0);
    }
    previousClicked(): void {
        if (this.imageNumber > 0) {
            this.getDisplayImage(this.imageNumber-1);
        }
    }
    getDisplayImage(imageNumber: number): void {

        if (Global.IsDemo()) {

            this.imageNumber = imageNumber;
            this.renderTargetImage(imageNumber, imageNumber);

        } else {

            const cal =  this.getVehicleCalibration();
            if (cal === null) return;

            this.sendingImage = true;

            const dr = new DataRequest();
            const targetRenderer: TargetRendererDto = {
                DeviceGuid: Global.CalibrationSession!.DeviceGuid,
                CalibrationSessionGuid: Global.CalibrationSession!.CalibrationSessionGuid,
                TargetImageIndex: imageNumber,
            } as TargetRendererDto;
            dr.$post<TargetRendererDto, TargetRendererDto>("/Service/TargetRenderer", null, targetRenderer)
                .then((rendererResult: TargetRendererDto): void => {
                    this.sendingImage = false;
                    if (rendererResult.UpdateResult.Success) {
                        this.imageNumber = imageNumber;
                        this.receivingImageConfirmation = true;
                        CalibrationEventSupport.sendEventTargetImageUploadId(CalibrationEvent.VehicleTargetImageSelected, rendererResult.TargetImageUpload!.UploadId!, this.imageNumber);
                    } else {
                        confirmOk(rendererResult.UpdateResult.Message || "An error occurred rendering the target image");
                    }
                })
                .catch((reason): void => {
                    this.sendingImage = false;
                    const msg = `An unexpected error occurred rendering the target image - ${reason}`;
                    this.clearTargetImage();
                    CalibrationEventSupport.sendEventDeviceFailure(msg);
                    confirmOk(msg);
                    Global.MainApp.clearSession();
                    this.$router.push("/");
                });
        }
    }

    cancelClicked(): void {
        confirmYesNo("Are you sure you want to cancel this calibration?")
            .then((clicked: ButtonClicked): void => {
                if (clicked === ButtonClicked.Ok) {
                    this.clearTargetImage();
                    CalibrationEventSupport.sendEvent(CalibrationEvent.CalibrationCustomerCanceled);
                    Global.MainApp.clearSession();
                    this.$router.push("/");
                }
            });
    }

    successClicked(): void {
        CalibrationEventSupport.sendEvent(CalibrationEvent.ImageCalibrationSucceeded);
        if (this.imageNumber < this.totalImages-1) {
            this.getDisplayImage(this.imageNumber+1);
            return;
        }
        this.clearTargetImage();
        CalibrationEventSupport.sendEvent(CalibrationEvent.CalibrationSuccessful);
        this.$router.push("/Calibration/Complete");
    }
    failedClicked(): void {
        this.clearTargetImage();
        this.$router.push("/Calibration/MeasuredWindshieldAngle");
    }

    loadVehicleInfo(): void {

        const vehicleId = Global.CalibrationSession!.VehicleId!;

        if (Global.IsDemo()) {

            this.totalImages = Global.CalibrationSession?.CalibrationType === CalibrationType.Sequential ? 3 : 1;
            this.sendFirstImageClicked();

        } else {

            this.connecting = true;
            const dr = new DataRequest();
            dr.$get<VehicleDto>(`/Service/Vehicle/${vehicleId}`)
                .then((vehicle: VehicleDto): void => {
                    this.connecting = false;
                    this.vehicle = vehicle;

                    const cal = this.getVehicleCalibration();
                    if (cal == null) {
                        confirmOk(`There are no calibration types defined for ${this.vehicleDescription}.`);
                        Global.MainApp.clearSession();
                        this.$router.push("/");
                        return;
                    }
                    this.totalImages = cal.VehicleCalibrationTargetImages.length;
                    this.sendFirstImageClicked();
                })
                .catch((reason): void => {
                    const msg = `No vehicle information available for vehicle ID ${vehicleId}.`;
                    CalibrationEventSupport.sendEventDeviceFailure(msg);
                    confirmOk(msg);
                    Global.MainApp.clearSession();
                    this.$router.push("/");
                });
        }
    }

    getVehicleCalibration(): VehicleCalibrationDto|null {
        const vehicleCalibrationId = Global.CalibrationSession!.VehicleCalibrationId!;
        const cals = this.vehicle!.VehicleCalibrations.filter((cal: VehicleCalibrationDto, index: number): boolean => {
            return vehicleCalibrationId === cal.VehicleCalibrationId;
        });
        if (cals.length === 0) return null;
        return cals[0];
    }

    renderTargetImage(targetImageUploadId: number, imageNumber: number) : void {

        this.targetImageUploadId = targetImageUploadId;
        this.receivingImageConfirmation = false;
        this.imageSource = "";
        this.imageNumber = imageNumber;

        let url = "";
        if (Global.IsDemo()) {
            url = Utility.formatUrl(`/demo/targets/target${targetImageUploadId}.png`, {});
        } else {
            url = Utility.formatUrl(`/Service/TargetRenderer/GetContentById/${targetImageUploadId}`, {}, Global.GetWebAPIUrl(), true);
        }

        if (AUGGIEAVAILABLE) {
            this.imageSource = Auggie.Target.ShowTarget(url);
        } else {
            this.imageSource = url;
        }
        this.getCurrentStatusForListeners();
    }

    clearTargetImage(): void {
        if (AUGGIEAVAILABLE) {
            Auggie.Target.ClearTarget();
        } else {
            // nothing to do
        }
    }

    getCurrentStatusForListeners(): void {
        if (this.targetImageUploadId != null) {
            CalibrationEventSupport.sendEventStatus({
                Page: "CalibrationSendTargets",
                CameraVerifying: false,
                TargetNumber: this.imageNumber,
                TotalTargets: this.totalImages,
                TargetUploadId: this.targetImageUploadId,
            } as DeviceStatusDto);
        }
    }

    mounted(): void {
        SignalR.onGetCurrentStatusForListeners = this.getCurrentStatusForListeners;
        SignalR.onRestartCalibration = this.failedClicked;
        SignalR.onSendPreviousTargetByCompanion = this.previousClicked;
        SignalR.onSendNextTargetByCompanion = this.successClicked;
        SignalR.onCalibrationFailed = (): void => {
            this.clearTargetImage();
            this.$router.push("/Calibration/Failed");
        };
        SignalR.onVehicleTargetImageReady = (targetImageUploadId: number, imageNumber: number): void => {
            this.renderTargetImage(targetImageUploadId, imageNumber);
        };
        SignalR.onCalibrationSuccess = (): void => {
            // no action (we sent it in successClicked), avoid out of sync popup
        };
        this.loadVehicleInfo();
    }
    unmounted(): void {
        SignalR.onGetCurrentStatusForListeners = null;
        SignalR.onRestartCalibration = null;
        SignalR.onSendPreviousTargetByCompanion = null;
        SignalR.onSendNextTargetByCompanion = null;
        SignalR.onCalibrationFailed = null;
        SignalR.onVehicleTargetImageReady = null;
        SignalR.onCalibrationSuccess = null;
    }
}
