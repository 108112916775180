import { Vue } from "vue-class-component";
import Debug from "@/shared/support/Debug";

export default class PageBase extends Vue {

    public static NewPageShownCallback: (() => void)|null = null;

    loading = false;
    pageLoading = true;
    pageGone = false;

    mounted(): void {
        Debug.verifyIds();
    }
    unmounted(): void {
        this.pageGone = true;
        // This is used by Auggie. The callback can be used to close any Help Modal that is displayed. This is
        // needed when the device is controlled remotely and a Help Modal happens to be up, we close them
        // whenever a new page is shown.
        if (PageBase.NewPageShownCallback) {
            PageBase.NewPageShownCallback();
        }
    }
}


