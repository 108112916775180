

import { Options } from "vue-class-component";

import PageBase from "@/shared/components/common/PageBase";
import HeaderMessage from "@/components/HeaderMessage.vue";
import { SignalR } from "@/support/SignalR";
import Toast from "@/shared/support/Toast";
import { CalibrationEventSupport } from "@/support/CalibrationEvent";
import { AUGGIEAVAILABLE } from "@/main";
import Utility from "@/shared/support/Utility";
import { Global } from "@/support/GlobalData";
import { DeviceStatusDto } from "@/shared/models/DeviceStatusDto";
import { CalibrationType } from "@/shared/enums/CalibrationType";

@Options({
    components: {
        HeaderMessage,
    }
})
export default class CalibrationInProgress extends PageBase {

    imageSource = "";
    targetNumber = -1;

    renderTargetImage(targetImageUploadId: number, targetNumber: number) : void {

        this.imageSource = "";
        this.targetNumber = targetNumber;
        Toast.info("New target image received");

        let url = "";
        if (Global.IsDemo()) {
            url = Utility.formatUrl(`/demo/targets/target${targetImageUploadId}.png`, {});
        } else {
            url = Utility.formatUrl(`/Service/TargetRenderer/GetContentById/${targetImageUploadId}`, {}, Global.GetWebAPIUrl(), true);
        }

        if (AUGGIEAVAILABLE) {
            this.imageSource = Auggie.Target.ShowTarget(url);
        } else {
            this.imageSource = url;
        }
        this.getCurrentStatusForListeners();
    }

    clearTargetImage(): void {
        if (AUGGIEAVAILABLE) {
            Auggie.Target.ClearTarget();
        } else {
            // nothing to do
        }
    }

    getCurrentStatusForListeners(): void {
        CalibrationEventSupport.sendEventStatus({
            Page: "CalibrationInProgress",
            TargetNumber: this.targetNumber,
        } as DeviceStatusDto);
    }

    mounted(): void {
        SignalR.onGetCurrentStatusForListeners = this.getCurrentStatusForListeners;
        SignalR.onTechnicianCameraRejected = (): void => {
            this.clearTargetImage();
            Toast.info("The technician requested repositioning and verifying the camera.");
            this.$router.push("/Calibration/CameraSetup");
        };
        SignalR.onCalibrationSuccess = (): void => {
            this.clearTargetImage();
            this.$router.push("/Calibration/Complete");
        };

        SignalR.onCalibrationFailed = (): void => {
            this.clearTargetImage();
            this.$router.push("/Calibration/Failed");
        };
        SignalR.onVehicleTargetImageReady = (targetImageUploadId: number, targetNumber: number): void => {
            this.renderTargetImage(targetImageUploadId, targetNumber);
        };
        if (Global.IsDemo()) {
            let total = 1;
            if (Global.CalibrationSession?.CalibrationType === CalibrationType.Sequential)
                total = 3;
            for (let count = 0 ; count < total ; ++count) {
                setTimeout((): void => {
                    this.renderTargetImage(count, count);
                }, count * Number(process.env.VUE_APP_DEMODELAY!) * 1000);
            }
            setTimeout((): void => {
                this.clearTargetImage();
                this.$router.push("/Calibration/Complete");
            }, total * Number(process.env.VUE_APP_DEMODELAY!) * 1000);
        }
        this.getCurrentStatusForListeners();
    }
    unmounted(): void {
        SignalR.onGetCurrentStatusForListeners = null;
        this.clearTargetImage();
        SignalR.onTechnicianCameraRejected = null;
        SignalR.onCalibrationSuccess = null;
        SignalR.onCalibrationFailed = null;
        SignalR.onVehicleTargetImageReady = null;
    }
}
