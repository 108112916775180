
export enum CalibrationEvent {
    TechnicianDeviceSelected=1,
    CalibrationSuccessful,
    CalibrationFailed,
    CalibrationCustomerCanceled,
    CalibrationTechnicianCanceled,
    CameraPositionAccepted,
    CameraPositionRejected,
    TechnicianCameraRejected,
    ParserReceivedRequest,
    RendererReceivedRequest,
    RendererProcessedRequest,
    VehicleTargetImageSelected,
    obsoleteVehicleTargetImageDisplayed,
    ImageCalibrationSucceeded,
    RestartCalibration,
    VehicleSelectionByTechnician,
    GetCameraAlignmentImage,
    CameraAlignmentImageAvailable,
    CameraAlignmentReached,
    CameraVerificationByTechnician,
    DeviceOutOfSync,
    RequestLightIntensity,
    CurrentLightIntensity,
    SetLightIntensity,

    CompanionDeviceSelected,
    StatusForListeners,
    AssistedCalibrationSelectedByCompanion,
    SelfCalibrationSelectedByCompanion,
    CalibrationCompanionCanceled,
    VehicleSelectionByCompanion,
    SendTargetByCompanion,
    SendPreviousTargetByCompanion,
    SendNextTargetByCompanion,
    AcknowledgeByCompanion,
    AssistedCalibrationSelected,
    SelfCalibrationSelected,
    DebugValues,
    AcknowledgeByDevice,

    VinSelection,
    VinSelectionByCompanion,
    TeamViewerStarted,
    StartTeamViewer,
    CameraVerificationByCompanion,
    MeasuredWindshieldAngleCorrectByCompanion,
    WindshieldAngleSelectionByCompanion,
    ContactSupportWindshieldAngleAcknowledgeByCompanion,
    GetCurrentStatusForListeners,
    DeviceFailure,
    CalibrationCustomerDisconnected,
    CalibrationTechnicianDisconnected,
    CalibrationCompanionDisconnected,
    AssistedTargetDisplaySelected,
    AssistedTargetDisplaySelectedByCompanion,

    RideHeightEntry,
    RideHeightEntryByCompanion,

    //DO NOT USE - retained to keep enum values in sync - Used for OLD Calibration Sessions
    CameraMisaligned,
    MisalignedCameraIssueResolved,
    MisalignedCameraIssueResolvedByTechnician,
    MisalignedCameraIssueResolvedByCompanion,
    MisalignedCameraNoIssueFoundProceed,
    MisalignedCameraNoIssueFoundProceedByTechnician,
    MisalignedCameraNoIssueFoundProceedByCompanion,
    //End of DO NOT USE
}

export const CalibrationEventDescription: string[] = [
    "???",
    "TechnicianDeviceSelected",
    "CalibrationSuccessful",
    "CalibrationFailed",
    "CalibrationCustomerCanceled",
    "CalibrationTechnicianCanceled",
    "CameraPositionAccepted",
    "CameraPositionRejected",
    "TechnicianCameraRejected",
    "ParserReceivedRequest",
    "RendererReceivedRequest",
    "RendererProcessedRequest",
    "VehicleTargetImageSelected",
    "VehicleTargetImageDisplayed",
    "ImageCalibrationSucceeded",
    "RestartCalibration",
    "VehicleSelectionByTechnician",
    "GetCameraAlignmentImage",
    "CameraAlignmentImageAvailable",
    "CameraAlignmentReached",
    "CameraVerificationByTechnician",
    "DeviceOutOfSync",
    "RequestLightIntensity",
    "CurrentLightIntensity",
    "SetLightIntensity",
    "CompanionDeviceSelected",
    "StatusForListeners",
    "AssistedCalibrationSelectedByCompanion",
    "SelfCalibrationSelectedByCompanion",
    "CalibrationCompanionCanceled",
    "VehicleSelectionByCompanion",
    "SendTargetByCompanion",
    "SendPreviousTargetByCompanion",
    "SendNextTargetByCompanion",
    "AcknowledgeByCompanion",
    "AssistedCalibrationSelected",
    "SelfCalibrationSelected",
    "DebugValues",
    "AcknowledgeByDevice",
    "VinSelection",
    "VinSelectionByCompanion",
    "TeamViewerStarted",
    "StartTeamViewer",
    "CameraVerificationByCompanion",
    "MeasuredWindshieldAngleCorrectByCompanion",
    "WindshieldAngleSelectionByCompanion",
    "ContactSupportWindshieldAngleAcknowledgeByCompanion",
    "GetCurrentStatusForListeners",
    "DeviceFailure",
    "The device connection was closed/lost",
    "The technician connection was closed/lost",
    "The Companion App connection was closed/lost",
    "AssistedTargetDisplaySelected",
    "AssistedTargetDisplaySelectedByCompanion",
    "RideHeightEntry",
    "RideHeightEntryByCompanion",
    "CameraMisaligned",
    "MisalignedCameraIssueResolved",
    "MisalignedCameraIssueResolvedByTechnician",
    "MisalignedCameraIssueResolvedByCompanion",
    "MisalignedCameraNoIssueFoundProceed",
    "MisalignedCameraNoIssueFoundProceedByTechnician",
    "MisalignedCameraNoIssueFoundProceedByCompanion",
];
