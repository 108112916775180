import { CalibrationEvent } from "@/shared/enums/CalibrationEvent";
import CalibrationEventDto from "@/shared/models/CalibrationEventDto";
import { DeviceStatusDto } from "@/shared/models/DeviceStatusDto";
import { DataRequest } from "@/shared/support/Data";
import { Global } from "./GlobalData";

export class CalibrationEventSupport {

    public static readonly MaxRetries = 100; // these events must be delivered, so retry a lot (up to 2 minutes, longer than signalr reconnect)
    public static Enabled = true; // debugging, turn off to disable events

    public static sendEvent(name: CalibrationEvent): void {
        if (Global.IsDemo()) return;
        if (!Global.CalibrationSession) return;
        const sessionGuid = Global.CalibrationSession.CalibrationSessionGuid;
        if (!sessionGuid) return;

        const dr = new DataRequest(undefined, undefined, CalibrationEventSupport.MaxRetries);
        const calibrationEvent = { CalibrationSessionGuid: sessionGuid, CalibrationEvent: name } as CalibrationEventDto;
        dr.$post<CalibrationEventDto>("/Service/CalibrationEvent", null, calibrationEvent); // fire and forget
    }

    public static sendEventTargetImageUploadId(name: CalibrationEvent, targetImageUploadId: number, imageNumber: number): void {
        if (Global.IsDemo()) return;
        if (!Global.CalibrationSession) return;
        const sessionGuid = Global.CalibrationSession.CalibrationSessionGuid;
        if (!sessionGuid) return;

        const dr = new DataRequest(undefined, undefined, CalibrationEventSupport.MaxRetries);
        const calibrationEvent = {
            CalibrationSessionGuid: sessionGuid,
            CalibrationEvent: name,
            TargetImageUploadId: targetImageUploadId,
            TargetNumber: imageNumber,
        } as CalibrationEventDto;
        dr.$post<CalibrationEventDto>("/Service/CalibrationEvent", null, calibrationEvent);
    }

    public static sendEventVin(name: CalibrationEvent, vin: string): void {
        if (Global.IsDemo()) return;
        if (!Global.CalibrationSession) return;
        const sessionGuid = Global.CalibrationSession.CalibrationSessionGuid;
        if (!sessionGuid) return;

        const dr = new DataRequest(undefined, undefined, CalibrationEventSupport.MaxRetries);
        const calibrationEvent = { CalibrationSessionGuid: sessionGuid, CalibrationEvent: name, Vin: vin } as CalibrationEventDto;
        dr.$post<CalibrationEventDto>("/Service/CalibrationEvent", null, calibrationEvent);
    }

    public static sendEventCurrentLightIntensity(name: CalibrationEvent, lightIntensity: number): void {
        if (Global.IsDemo()) return;
        if (!Global.CalibrationSession) return;
        const sessionGuid = Global.CalibrationSession.CalibrationSessionGuid;
        if (!sessionGuid) return;

        const dr = new DataRequest(undefined, undefined, CalibrationEventSupport.MaxRetries);
        const calibrationEvent = { CalibrationSessionGuid: sessionGuid, CalibrationEvent: name, LightIntensity: lightIntensity } as CalibrationEventDto;
        dr.$post<CalibrationEventDto>("/Service/CalibrationEvent", null, calibrationEvent); // fire and forget
    }

    public static sendEventTvStarted(name: CalibrationEvent, deviceName: string, techGuid: string|null): void {
        if (Global.IsDemo()) return;
        if (!Global.CalibrationSession) return;
        const sessionGuid = Global.CalibrationSession.CalibrationSessionGuid;
        if (!sessionGuid) return;

        const dr = new DataRequest(undefined, undefined, CalibrationEventSupport.MaxRetries);
        const calibrationEvent = {
            CalibrationSessionGuid: sessionGuid,
            CalibrationEvent: name,
            DeviceName: deviceName,
            TechnicianGuid: techGuid,
        } as CalibrationEventDto;
        dr.$post<CalibrationEventDto>("/Service/CalibrationEvent", null, calibrationEvent);
    }

    public static sendEventCalibrationRestart(name: CalibrationEvent, windshieldAngle: number|null): void {
        if (Global.IsDemo()) return;
        if (!Global.CalibrationSession) return;
        const sessionGuid = Global.CalibrationSession.CalibrationSessionGuid;
        if (!sessionGuid) return;

        const dr = new DataRequest(undefined, undefined, CalibrationEventSupport.MaxRetries);
        const calibrationEvent = {
            CalibrationSessionGuid: sessionGuid,
            CalibrationEvent: name,
            WindshieldOverride: windshieldAngle
        } as CalibrationEventDto;
        dr.$post<CalibrationEventDto>("/Service/CalibrationEvent", null, calibrationEvent);
    }

    public static sendEventOutOfSync(signalRMessage: string): void {
        if (Global.IsDemo()) return;
        if (!Global.CalibrationSession) return;
        const sessionGuid = Global.CalibrationSession.CalibrationSessionGuid;
        if (!sessionGuid) return;

        const dr = new DataRequest(undefined, undefined, CalibrationEventSupport.MaxRetries);
        const calibrationEvent = {
            CalibrationSessionGuid: sessionGuid,
            CalibrationEvent: CalibrationEvent.DeviceOutOfSync,
            OutOfSyncSignalR: signalRMessage,
            OutOfSyncUrl: window.location.href,
        } as CalibrationEventDto;
        dr.$post<CalibrationEventDto>("/Service/CalibrationEvent", null, calibrationEvent);
    }
    public static sendEventDeviceFailure(msg: string): void {
        if (Global.IsDemo()) return;
        if (!Global.CalibrationSession) return;
        const sessionGuid = Global.CalibrationSession.CalibrationSessionGuid;
        if (!sessionGuid) return;

        const dr = new DataRequest(undefined, undefined, CalibrationEventSupport.MaxRetries);
        const calibrationEvent = {
            CalibrationSessionGuid: sessionGuid,
            CalibrationEvent: CalibrationEvent.DeviceFailure,
            DeviceFailureMessage: msg,
        } as CalibrationEventDto;
        dr.$post<CalibrationEventDto>("/Service/CalibrationEvent", null, calibrationEvent);
    }
    public static sendEventRideHeight(name: CalibrationEvent, frontLeft: number, frontRight: number, rearLeft: number, rearRight: number): void {
        if (Global.IsDemo()) return;
        if (!Global.CalibrationSession) return;
        const sessionGuid = Global.CalibrationSession.CalibrationSessionGuid;
        if (!sessionGuid) return;

        const dr = new DataRequest(undefined, undefined, CalibrationEventSupport.MaxRetries);
        const calibrationEvent = {
            CalibrationSessionGuid: sessionGuid,
            CalibrationEvent: name,
            RideHeightFrontLeft: frontLeft,
            RideHeightFrontRight: frontRight,
            RideHeightRearLeft: rearLeft,
            RideHeightRearRight: rearRight } as CalibrationEventDto;
        dr.$post<CalibrationEventDto>("/Service/CalibrationEvent", null, calibrationEvent);
    }

    public static sendEventStatus(data: DeviceStatusDto|null): void {
        if (Global.IsDemo()) return;
        if (!Global.CalibrationSession) throw new Error("Sending event without active session");
        const sessionGuid = Global.CalibrationSession.CalibrationSessionGuid;
        if (!sessionGuid) return;

        if (data) {
            /* eslint-disable no-prototype-builtins */
            if (!data.hasOwnProperty("DeviceName")) data.DeviceName = Global.DeviceName;
            if (!data.hasOwnProperty("AllowAssistedCalibration")) data.AllowAssistedCalibration = Global.AllowAssistedCalibration;
            if (!data.hasOwnProperty("AllowAssistedTargetDisplay")) data.AllowAssistedTargetDisplay = Global.AllowAssistedTargetDisplay;
            if (!data.hasOwnProperty("AllowSelfCalibration")) data.AllowSelfCalibration = Global.AllowSelfCalibration;
            if (!data.hasOwnProperty("SessionType")) data.SessionType = Global.SessionType;
            if (!data.hasOwnProperty("Make")) data.Make = Global.Make;
            if (!data.hasOwnProperty("Model")) data.Model = Global.Model;
            if (!data.hasOwnProperty("Year")) data.Year = Global.Year;
            if (!data.hasOwnProperty("CalibrationType")) data.CalibrationType = Global.Calibration;
            if (!data.hasOwnProperty("LegLengthA")) data.LegLengthA = Global.LegLengthA;
            if (!data.hasOwnProperty("LegLengthB")) data.LegLengthB = Global.LegLengthB;
            if (!data.hasOwnProperty("LegLengthC")) data.LegLengthC = Global.LegLengthC;
            if (!data.hasOwnProperty("Marker")) data.Marker = Global.Marker;
            if (!data.hasOwnProperty("TechnicianName")) data.TechnicianName = Global.TechnicianName;
            if (!data.hasOwnProperty("VehicleId")) data.VehicleId = Global.VehicleId;
            if (!data.hasOwnProperty("IsTechControlled")) data.IsTechControlled = Global.IsTechControlled;
            if (!data.hasOwnProperty("WheelArchHeightMin")) data.WheelArchHeightMin = Global.WheelArchHeightMin;
            if (!data.hasOwnProperty("WheelArchHeightMax")) data.WheelArchHeightMax = Global.WheelArchHeightMax;
        }

        const dr = new DataRequest(undefined, undefined, CalibrationEventSupport.MaxRetries);
        const calibrationEvent = {
            CalibrationSessionGuid: sessionGuid,
            CalibrationEvent: CalibrationEvent.StatusForListeners,
            DeviceStatus: data ? JSON.stringify(data) : null,
        } as CalibrationEventDto;
        dr.$post<CalibrationEventDto>("/Service/CalibrationEvent", null, calibrationEvent); // fire and forget
    }
}