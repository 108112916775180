

import { Options } from "vue-class-component";

import PageBase from "@/shared/components/common/PageBase";
import HeaderMessage from "@/components/HeaderMessage.vue";
import { ButtonClicked } from "@/shared/enums/ButtonClicked";
import { confirmYesNo } from "@/shared/components/common/AlertDialog.vue";
import { Global } from "@/support/GlobalData";
import { SignalR } from "@/support/SignalR";
import { CalibrationEventSupport } from "@/support/CalibrationEvent";
import { CalibrationEvent } from "@/shared/enums/CalibrationEvent";
import { DeviceStatusDto } from "@/shared/models/DeviceStatusDto";

@Options({
    components: {
        HeaderMessage,
    }
})
export default class CalibrationVin extends PageBase {

    vin: string = "";

    isCompanionControlled = false;
    isTechControlled(): boolean {
        return Global.IsTechControlled;
    }
    isControlled(): boolean {
        return this.isCompanionControlled || Global.IsTechControlled;
    }
    get isValid(): boolean {
        return this.vin.match(/^[0-9]{5}$/) != null;
    }
    accept(): void {
        if (!this.isValid) return;
        this.nextClicked();
    }
    nextClicked(): void {
        CalibrationEventSupport.sendEventVin(CalibrationEvent.VinSelection, this.vin);
        this.$router.push("/Calibration/RideHeight");
    }

    setVinByCompanion(vin: string): void {
        this.vin = vin;
        this.isCompanionControlled = true;
        this.$router.push("/Calibration/RideHeight");
    }

    cancelClicked(): void {
        confirmYesNo("Are you sure you want to cancel this calibration?")
            .then((clicked: ButtonClicked): void => {
                if (clicked === ButtonClicked.Ok) {
                    CalibrationEventSupport.sendEvent(CalibrationEvent.CalibrationCustomerCanceled);
                    Global.MainApp.clearSession();
                    this.$router.push("/");
                }
            });
    }

    getCurrentStatusForListeners(): void {
        CalibrationEventSupport.sendEventStatus({
            Page: "CalibrationVin",
        } as DeviceStatusDto);
    }

    mounted(): void {
        SignalR.onGetCurrentStatusForListeners = this.getCurrentStatusForListeners;
        SignalR.onVinSelectionByCompanion = this.setVinByCompanion;
        this.getCurrentStatusForListeners();
    }

    unmounted(): void {
        SignalR.onGetCurrentStatusForListeners = null;
        SignalR.onVinSelectionByCompanion = null;
    }

}
