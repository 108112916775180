

import Events from "@/shared/support/Events";
import { Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

export default class ButtonButton extends Vue {

    @Prop({ default: "" }) readonly class: string = "";

    inverted = false;
    flash = process.env.VUE_APP_FLASHBUTTON === "1";

    mousedown(ev: MouseEvent): void {
        if (!this.flash) return;
        this.inverted = true;
        this.$forceUpdate();
        Events.bubbleEvent(this, "mousedown", ev);
        setTimeout((): void => {
            this.inverted = false;
        }, 1000);
    }

    getCss(): string {
        return `${this.class}${this.inverted ? " inverted" : ""}`;
    }
}

