import { renderSlot as _renderSlot, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    class: _ctx.getRowCss()
  }, [
    _createVNode("div", {
      class: _ctx.getLabelCss()
    }, [
      _renderSlot(_ctx.$slots, "label")
    ], 2),
    _createVNode("div", {
      class: _ctx.getFieldsCss()
    }, [
      _renderSlot(_ctx.$slots, "fields")
    ], 2)
  ], 2))
}