export enum GenericActiveStatus {
    All = 0,
    Active = 1,
    Inactive = 2,
}

export const GenericActiveStatusDescription: string[] = [
    "All", 
    "Active",
    "Inactive",
];