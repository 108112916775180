import { toDisplayString as _toDisplayString, renderSlot as _renderSlot, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  value: ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createBlock("select", {
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectedKey = $event)),
    disabled: _ctx.readonly
  }, [
    (_ctx.addSelect)
      ? (_openBlock(), _createBlock("option", _hoisted_1, [
          _createTextVNode("<--" + _toDisplayString(_ctx.prefix) + " ", 1),
          _renderSlot(_ctx.$slots, "default"),
          _createTextVNode(" " + _toDisplayString(_ctx.suffix) + "-->", 1)
        ]))
      : _createCommentVNode("", true),
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.sortedList, (option) => {
      return (_openBlock(), _createBlock("option", {
        value: option.Key,
        selected: _ctx.isMatch(option.Key)
      }, _toDisplayString(option.Value), 9, ["value", "selected"]))
    }), 256))
  ], 8, ["disabled"])), [
    [_vModelSelect, _ctx.selectedKey]
  ])
}