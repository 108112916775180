

import { Vue, Options } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import Toast from "@/shared/support/Toast";

export enum ValidationLevel {
    Show = 1,
    Test = 2,
    Validate = 3,
    Reset = 4,
}

@Options({
})
export default class ValidationMessage extends Vue {

    @Prop({ default: "" }) readonly modelValue: string = "";

    public static validate(errors: string[] | undefined, show: ValidationLevel | undefined,
        validationFunction: () => string|null,
        setError: (message: string) => void) : void {
        if (show === undefined)
            show = ValidationLevel.Validate;

        if (show === ValidationLevel.Reset)
            setError("");

        const msg = validationFunction();
        if (msg && errors)
            errors.push(msg);
        if (show === ValidationLevel.Show || show === ValidationLevel.Validate)
            setError(msg || "");
    }
    public static display(errors: string[]): void {
        Toast.errorList(errors);
    }

}

