

import { Vue, Options } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
})
export default class SpinX extends Vue {

    @Prop({ default: false }) readonly disabled: boolean = false;
    @Prop({ default: false }) readonly spinning: boolean = false;

}

