

import { Options } from "vue-class-component";

import PageBase from "@/shared/components/common/PageBase";
import HeaderMessage from "@/components/HeaderMessage.vue";
import { CalibrationEventSupport } from "@/support/CalibrationEvent";
import { DeviceStatusDto } from "@/shared/models/DeviceStatusDto";
import { SignalR } from "@/support/SignalR";
import { CalibrationEvent } from "@/shared/enums/CalibrationEvent";

@Options({
    components: {
        HeaderMessage,
    }
})
export default class CalibrationContactSupportWindshieldAngle extends PageBase {

    okClicked(): void {
        CalibrationEventSupport.sendEventStatus({
            Page: "CalibrationContactSupportWindshieldAngle",
        } as DeviceStatusDto);
        CalibrationEventSupport.sendEventCalibrationRestart(CalibrationEvent.RestartCalibration, null);
    }

    getCurrentStatusForListeners(): void {
        CalibrationEventSupport.sendEventStatus({
            Page: "CalibrationContactSupportWindshieldAngle",
        } as DeviceStatusDto);
    }

    mounted(): void {
        SignalR.onGetCurrentStatusForListeners = this.getCurrentStatusForListeners;
        SignalR.onContactSupportWindshieldAngleAcknowledgeByCompanion = this.okClicked;
        this.getCurrentStatusForListeners();
    }
    unmounted(): void {
        SignalR.onGetCurrentStatusForListeners = null;
        SignalR.onContactSupportWindshieldAngleAcknowledgeByCompanion = null;
    }
}
