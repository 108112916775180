

import { Options } from "vue-class-component";

import PageBase from "@/shared/components/common/PageBase";
import HeaderMessage from "@/components/HeaderMessage.vue";
import PowerOff from "@/components/PowerOff.vue";

@Options({
    components: {
        HeaderMessage,
        PowerOff,
    }
})
export default class CalibrationComplete extends PageBase {
    get abortError(): string {
        const err = Auggie.PackageDownload.AbortError();
        if (!err) return "";
        return ` ${err}`;
    }
    restartClicked(): void {
        Auggie.System.Restart();
    }
}
